import { Navigate } from "react-router-dom";

import Layout from "../layout/layout";
import {useAuth} from "./authProvider";

export const ProtectedRoute = () => {

    const {stores} = useAuth();
    const {cookies} = useAuth();

    // Check if the user is authenticated
    if (!cookies.token) {
        // If not authenticated, redirect to the login page
        return <Navigate to="/login" />;
    }

    // If authenticated, render the child routes
    return <Layout user={stores.user} course={stores.course}/>;
};
