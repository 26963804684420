import React, {useEffect, useState} from "react";
import {Link, Outlet, useLocation, useNavigate} from "react-router-dom";
import Modal from '../../../components/modal'
import Popper from '../../../components/popper'
import Select from 'react-select';

import FetchClient from "../../../utils/fetchClient";
import {useLayoutContext} from "../../../layout/layoutProvider";
import ListMenu from "../../../components/listmenu";
import WordCloud from 'react-d3-cloud';
import {createRoot} from "react-dom/client";
import {openCloseSidebarPanel} from "../../../utils/helpers";
import {useAuth} from "../../../utils/authProvider";
import {Dialog} from "@mui/material";
import {CustomStyles, Option} from "../../../components/selectOptions";

const ChatSidebarMenu = (props) => {
    const location = useLocation()
    const navigate = useNavigate();

    const {setLoader, sidebarSelectedMenuItem} = useLayoutContext();
    const {stores, setStore} = useAuth()

    const user = props.user;
    const course = props.course;
    const [chats, setChats] = useState([]);
    const [createChatModal, setCreateChatModel] = useState(false);
    const [assistants, setAssistants] = useState([]);
    const [selectedAssistant, setSelectedAssistant] = useState({name: ""});
    const [assistantEditPopper, setAssistantEditPopper] = useState();
    const [assistantCreatePopper, setAssistantCreatePopper] = useState();
    const [inputs, setInputs] =
        useState({title: "", action: "create"});
    const [selectedChatType, setSelectedChatType] = useState([])
    const [selectedChat, setSelectedChat] = useState()
    const [courseFormInputs, setCourseFormInputs] = useState({});
    // const [courseFormModal, setCourseFormModal] = useState({});
    const [courseFormError, setCourseFormError] = useState({});

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [pageNumbers, setPageNumbers] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const chatTypesList = [{
        value: 0, label: "general"
    }, {value: 1, label: "exercise"}, {value: 2, label: "wizard"}]
    const handleChangeItemsPerPage = (value) => {
        setItemsPerPage(value);
        setCurrentPage(1);
    };
    useEffect(() => {
        const indexOfLastItem = currentPage * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;
        setFilteredData(chats.slice(indexOfFirstItem, indexOfLastItem))
    }, [currentPage]);
    useEffect(() => {
        setPageNumbers([]);

        for (let i = 1; i <= Math.ceil(chats?.length / itemsPerPage); i++) {
            setPageNumbers(prevPageNumbers => [...prevPageNumbers, i]);
        }
        const indexOfLastItem = currentPage * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;
        if (chats && chats.length > 0) {
            setFilteredData(chats.slice(indexOfFirstItem, indexOfLastItem))
        }

    }, [chats, itemsPerPage]);

    const handleChange = (event) => {
        const {name, value} = event.target;
        setInputs(values => ({...values, [name]: value}))
    };
    const keyupCreateHandler = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault(); 
            submitChat(event); 
        }
    };
    const keyUpEditHandler = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            updateChat(event);
        }
    };

    const submitChat = (event) => {
        event.preventDefault(); 
        setCreateChatModel(false); 
        FetchClient({
            method: 'post',
            url: `chats`,
            data: {
                title: inputs.title,
                type: "general",
                model: 'gpt-3.5-turbo',
                user_id: user.id,
                course_id: course.id,
                assistant_id: selectedAssistant.id
            },
        }).then(response => {
            setInputs({ title: "" });
            window.$notification({
                text: 'Chat created successfully',
                variant: 'success',
                position: 'center-top'
            });
            getChats(user.id, course.id, selectedChatType, false);
            setTimeout(() => {
                navigate("/chats/chat/" + response.data.id, { replace: true });
            }, 2000);
        });
    };
    const updateModal = document.querySelector("#updateChatModal");
    const updateChat = (event) => {
        event.preventDefault();
        updateModal.style.display = "none";
        setLoader(true);
        selectedChat.title = inputs.title;
        selectedChat.assistant_id = selectedAssistant.id;
        FetchClient.put('chats/' + selectedChat.id, selectedChat)
            .then(response => {
                window.$notification({
                    text: 'Chat updated successfully',
                    variant: 'success',
                    position: 'center-top'
                });
                setInputs({title: ""});
                let response_chat = chats.findIndex((obj => obj.id === selectedChat.id));
                chats[response_chat].title = response.data.title;
                setChats(chats);
                navigate("/chats/chat/" + response.data.id, {replace: true});
            });

    }
    const getAssistants = () => (
        FetchClient({
            method: 'get',
            url: 'assistants',
            params: {}
        }).then(response => {
            setAssistants(response.data);
            setSelectedAssistant(response.data[0])
            const config = {
                placement: "bottom-start",
                modifiers: [
                    {
                        name: "offset",
                        options: {
                            offset: [0, 4],
                        },
                    },
                ],
            };
            setAssistantCreatePopper(new Popper("#dropdown-wrapper-create", ".popper-ref", ".popper-root", config));
            setAssistantEditPopper(new Popper("#dropdown-wrapper-edit", ".popper-ref", ".popper-root", config));
        })
    );
    const selectAssistant = (assistant) => {
        setSelectedAssistant(assistant);
    }

    const getChats = (userId, courseId, sideBarStatus) => {
        FetchClient({
            method: 'get',
            url: 'user/' + userId + '/course/' + courseId + '/chats',
            params: {
                user_id: userId,
                course_id: courseId,
                type: "any"
            }
        }).then(response => {
            setChats(response.data.items);
            setFilteredData(response.data.items);
            setTimeout(() => {
                if (sideBarStatus === undefined) {

                    openCloseSidebarPanel(true);
                } else {
                    openCloseSidebarPanel(false);

                }
            }, 300);

            setLoader(false);
        })
    };

    function getKeywords(chatId) {
        FetchClient({
            method: 'get',
            url: 'chat/' + chatId + '/keywords',
            params: {
                chat_id: chatId
            }
        }).then(response => {
            let keywords = [];
            for (let i = 0; i < response.data.length; i++) {
                keywords.push({text: response.data[i].title, value: response.data[i].proportion})
            }
            if (keywords.length > 0) {
                document.getElementById('no-keyword').textContent = "";
                createRoot(document.getElementById('chatsidebar-wordcloud-canvas')).render(<WordCloud width={1000}
                                                                                                      height={100}
                                                                                                      data={keywords}
                                                                                                      font="Candara"
                                                                                                      fontWeight="bold"
                                                                                                      rotate={(word) => 0}/>);
            } else {
                document.getElementById('no-keyword').textContent = "No Keyword Extracted!";
                createRoot(document.getElementById('chatsidebar-wordcloud-canvas')).render(<WordCloud width={1000}
                                                                                                      height={100}
                                                                                                      data={keywords}
                                                                                                      font="Candara"
                                                                                                      fontWeight="bold"
                                                                                                      rotate={(word) => 0}/>);
            }
        })
    }

    const fetchUser = () => {
        FetchClient({
            method: 'get',
            url: `profile`,
            data: {}
        }).then(response => {
            const user = response.data;
            setStore("user", user);
            getChats(user?.id, course.id);
        }).catch((error) => {

        })
    }
    useEffect(() => {
        setLoader(true);
        getAssistants();
        if (user) {
            getChats(stores?.user?.id, course.id);
        } else {
            fetchUser()
        }

    }, []);

    function selectThisChat(event, chat) {
        event.preventDefault();
        setSelectedChat(chat);
        inputs.title = chat.title;
        setInputs(inputs);
        setSelectedAssistant(assistants[assistants.findIndex((obj => obj.id === chat.assistant_id))]);
    }

    const handleDeleteChat = (event) => {
        event.preventDefault();
        setLoader(true);
        FetchClient.delete('chats/' + selectedChat.id, {
            data: {
                chat_id: selectedChat.id
            }
        }).then(response => {
            let updatedChats = chats.filter(chat => chat.id !== selectedChat.id);
            setChats(updatedChats);
            window.$notification({text: 'Chat deleted successfully!', variant: 'error', position: 'center-top'})
            setLoader(false);

            navigate("/" + sidebarSelectedMenuItem, {replace: true});
        });

    }

    useEffect(() => {
        const listMenuWrapper = document.querySelector(`#${"chatListMenuWrapper"}`);
        if (listMenuWrapper) {
            new ListMenu(listMenuWrapper);
        }
    }, [chats]);

    const selectChatType = (chatType, index) => {
        if (chatType.length === 0) {
            setSelectedChatType(chatType);
            getChats(user?.id, course?.id);
        } else {
            if (index?.option?.value === -1) {
                setSelectedChatType(chatTypesList);
                openCloseSidebarPanel(true);
            } else {
                setSelectedChatType(chatType);
                const filteredArray = chats.filter(item =>
                    chatType.some(type => type.label === item.type))
                openCloseSidebarPanel(true);
                setSelectedChatType(chatType);
                setChats([]);
                setFilteredData(filteredArray);
                setChats(filteredArray)
            }
            ;
        }
    }

    const openCreateChatModal = (event) => {
        setInputs({title: ""});
        checkCourseFormData();
    };

    const handleCloseCreateChatModel = (event) => {
        // event.preventDefault();
        setCreateChatModel(false);
        setInputs({title: ""});

    }

    // const openCourseFormModal = () => {
    //     setCourseFormInputs({});
    //     // courseFormModal.open();
    // }
    const checkCourseFormData = () => {
        setCreateChatModel(true);
        // FetchClient({
        //     method: 'get',
        //     url: '/course/form/answer/' + course.id + "/" + user.id
        // }).then(response => {
        //     if (response.data !== null && response.data.id !== 0) {
        //     } else {
        //         // openCourseFormModal();
        //     }
        // }).catch((error) => {
        //     if (error.response) {
        //         // openCourseFormModal();
        //         return false;
        //     }
        // });
    }
    const handleCourseFormInputChange = (event) => {
        setCourseFormError({message: "", customized_error_code: -1});

        const {name, value} = event.target;
        setCourseFormInputs(values => ({...values, [name]: value}))
    }

    // function submitCourseForm(event) {
    //     event.preventDefault();
    //     setLoader(true);
    //     if (courseFormInputs.sex === '' || courseFormInputs.age === '' || courseFormInputs.gender === ''
    //         || courseFormInputs.gpa === '' || courseFormInputs.quantile === '' || courseFormInputs.knowledge === ''
    //         || courseFormInputs.frequency === '' || courseFormInputs.usefulness === '' || courseFormInputs.feelings === ''
    //         || courseFormInputs.sex === undefined || courseFormInputs.age === undefined || courseFormInputs.gender === undefined
    //         || courseFormInputs.gpa === undefined || courseFormInputs.quantile === undefined || courseFormInputs.knowledge === undefined
    //         || courseFormInputs.frequency === undefined || courseFormInputs.usefulness === undefined || courseFormInputs.feelings === undefined) {
    //         setCourseFormError({message: "Please fill all the fields.", customized_error_code: 10000});
    //     } else {
    //         FetchClient({
    //             method: 'post',
    //             url: '/course/form/answer',
    //             data: {
    //                 course_id: course.id,
    //                 student_id: user.id,
    //                 answers: courseFormInputs
    //             }
    //         }).then(response => {
    //             window.$notification({
    //                 text: 'User edited successfully!',
    //                 variant: 'primary',
    //                 position: 'center-top'
    //             })
    //             setLoader(false);
    //             courseFormModal.close();
    //         });
    //     }
    // }
    useEffect(() => {
        if (selectedChatType !== 'exercise') {
            new Modal("#deleteChatModal");
            new Modal("#updateChatModal");
        }
        new Modal("#infoChatModal");
    }, [selectChatType]);


    const assistantItems = assistants.map((a) => (
        <li>
            <a
                href="#"
                onClick={(event) => selectAssistant(event, a)}
                className="flex items-center space-x-3.5 px-4 py-2 pr-8 tracking-wide outline-none transition-all hover:bg-slate-100 focus:bg-slate-100 dark:hover:bg-navy-600 dark:focus:bg-navy-600"
            >
                <div className="avatar h-10 w-10">
                    <img
                        className="rounded-full"
                        src={a.image_file_url}
                        alt="avatar"
                    />
                </div>
                <div>
                    <p className="text-slate-700 line-clamp-1 dark:text-navy-100">
                        {a.name}
                    </p>
                    <p className="text-xs text-slate-500 dark:text-navy-300">
                        {a.description}
                    </p>
                </div>
            </a>
        </li>
    ));
    const handleRoute = (id) => {
        openCloseSidebarPanel(false);
        setTimeout(() => {
            navigate("/chats/chat/" + id)
        }, 300);
    }
    const urlId = location.pathname.split('/').pop();
    const chatItems = filteredData.map((m) => (

        <li onClick={() => handleRoute(m.id)}
            className={`flex flex-row items-center ${m.id == location.pathname.split('/').pop() ? "!dark:bg-slate-600 bg-slate-300 rounded-lg" : ""}`}
            key={'chat_item_' + m.id}>
            <div
                id={m.id}
                // data-menu-id={"chatlistmenu_" + m.id}
                // to={"/chats/chat/" + m.id}
                // data-default-class="text-slate-600 hover:text-slate-800 dark:text-navy-200 dark:hover:text-navy-50"
                // data-active-class="font-medium text-primary dark:text-accent-light"
                className={"flex-1 nav-link flex cursor-pointer py-2 text-xs+ tracking-wide outline-none transition-colors duration-300 ease-in-out"
                    + (m.unread_messages_count > 0 ? " font-bold" : "")}
            >
                <span className="ml-2">{m.title}</span>

                <div
                    className={"-mt-1 flex h-4 min-w-[1rem] items-center justify-center rounded-full bg-primary px-1 text-tiny font-medium leading-none text-white dark:bg-accent"
                        + (m.unread_messages_count > 0 ? "" : " hidden")}
                >
                    {m.unread_messages_count}
                </div>
            </div>
            {selectedChatType !== 'exercise' ?
                <button
                    className="flex darkmode-toggle btn h-6 w-6 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25"
                    data-toggle="modal"
                    data-target="#updateChatModal"
                    id={"edit" + m.id}
                    onClick={(event) => selectThisChat(event, m)}
                >
                    <img
                        className="mx-auto w-4 h-4"
                        src={process.env.PUBLIC_URL + '/images/buttons/edit.png'}
                        alt="edit"
                    />
                </button>
                :
                <></>
            }
            {selectedChatType !== 'exercise' ?
                <button
                    className="flex darkmode-toggle btn h-6 w-6 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25"
                    data-toggle="modal"
                    data-target="#deleteChatModal"
                    id={"delete" + m.id}
                    onClick={(event) => selectThisChat(event, m)}
                >
                    <img
                        className="mx-auto w-4 h-4"
                        src={process.env.PUBLIC_URL + '/images/buttons/delete.png'}
                        alt="edit"
                    />
                </button>
                :
                <></>
            }
            <button
                className="flex darkmode-toggle btn h-6 w-6 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25"
                data-toggle="modal"
                data-target="#infoChatModal"
                id={"info" + m.id}
                onClick={(event) => {
                    selectThisChat(event, m);
                    getKeywords(m.id);
                }}
            >
                <img
                    className="mx-auto w-4 h-4"
                    src={process.env.PUBLIC_URL + '/images/buttons/info.png'}
                    alt="info"
                />
            </button>
        </li>
    ));
    return <div>
        <div className="sidebar-panel">
            <div id="page-sidebar-panel"
                 className="flex  h-full grow flex-col bg-white pl-[var(--main-sidebar-width)] dark:bg-navy-750"
            >
                {/* <div id="tab-wrapper-chat-sidebar" className="tabs flex flex-col pl-1 pr-1 pt-4">
                <button onClick={()=>openCloseSidebarPanel(false)}
                        className="sidebar-close btn h-7 w-7 rounded-full p-0 text-primary hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:text-accent-light/80 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25 xl:hidden"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M15 19l-7-7 7-7"
                            />
                        </svg>
                    </button>
                    <div
                        className="is-scrollbar-hidden overflow-x-auto rounded-lg bg-slate-200 text-slate-600 dark:bg-navy-800 dark:text-navy-200"
                    >
                        
                        <div className=" flex px-1.5 py-1 items-center">

                            <button
                                data-target="#tab5-home"
                                onClick={(event) => selectChatType("general")}
                                className="tab btn shrink-0 space-x-2 px-3 py-1.5 font-medium"
                                data-default-class="hover:text-slate-800 focus:text-slate-800 dark:hover:text-navy-100 dark:focus:text-navy-100"
                                data-active-class="bg-white shadow dark:bg-navy-500 dark:text-navy-100"
                            >
                                <span> Chats </span>
                            </button>
                            <button
                                data-target="#tab5-profile"
                                onClick={(event) => selectChatType("exercise")}
                                className="tab btn shrink-0 space-x-2 px-3 py-1.5 font-medium"
                                data-default-class="hover:text-slate-800 focus:text-slate-800 dark:hover:text-navy-100 dark:focus:text-navy-100"
                                data-active-class="bg-white shadow dark:bg-navy-500 dark:text-navy-100"
                            >
                                <span> Exercise Chats </span>
                            </button>
                        
                        </div>
                    </div>
                    <div className="pt-4">
                        <div className="tab-content tab-shift-left" id="tab-chat-sidebar-general">
                        </div>
                        <div className="tab-content tab-shift-left" id="tab-chat-sidebar-exercise">
                        </div>
                    </div>
                </div>
                 */}
                <Select
                    id="select-type"
                    className="mt-1 p-4"

                    styles={CustomStyles}
                    loadingMessage={() => 'Loading ...'}
                    closeMenuOnSelect={true}
                    value={selectedChatType}
                    isMulti={true}
                    menuPortalTarget={document.body}
                    onChange={selectChatType}
                    options={[{
                        value: -1, label: "All"
                    }, ...chatTypesList]}
                />
                {selectedChatType !== 'exercise' ?
                    <div
                        className="nav-wrapper overflow-y-hidden overflow-x-hidden pb-6 text-center"
                        data-simplebar
                    >
                        <button
                            className={"btn mt-5 w-[calc(80%-1rem)] bg-primary font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90 "
                                + (course && course.status == 'subscribed' ? "" : "hidden")}
                            onClick={(event) => openCreateChatModal(event)}
                        >
                            Start a Chat
                        </button>

                        <div className="my-3 mx-4 h-px bg-slate-200 dark:bg-navy-500"></div>
                    </div>
                    :
                    <></>
                }
                <div id="chatListMenuWrapper"
                     className="nav-wrapper h-[calc(100%-4.5rem)] overflow-x-hidden pb-6"
                     data-simplebar
                >
                    <div className="listmenu">
                        <ul className="flex flex-1 flex-col px-4 font-inter">
                            {chatItems}
                        </ul>
                        <div className={"text-center " + (chats.length > 0 ? ' hidden' : '')}>
                            <span className={selectedChatType === 'exercise' ? ' hidden' : ''}>There is no chat!</span>
                            <span
                                className={selectedChatType !== 'exercise' ? ' hidden' : ''}>There is no exercise!</span>
                        </div>
                    </div>
                </div>
                <div
                    className="flex flex-col space-y-4 px-2 py-4 sm:flex-row sm:items-center sm:space-y-0 sm:px-2 text-center items-center justify-center"
                >
                    <ol className="pagination">
                        <li className="rounded-l-lg bg-slate-150 dark:bg-navy-500">
                            <a
                                href="#"
                                onClick={(event) => (currentPage > 1 ?
                                    setCurrentPage((prev) => prev - 1) : event.preventDefault())}
                                className="flex h-8 w-8 items-center justify-center rounded-lg text-slate-500 transition-colors hover:bg-slate-300 focus:bg-slate-300 active:bg-slate-300/80 dark:text-navy-200 dark:hover:bg-navy-450 dark:focus:bg-navy-450 dark:active:bg-navy-450/90"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-4 w-4"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M15 19l-7-7 7-7"
                                    />
                                </svg>
                            </a>
                        </li>
                        {pageNumbers.map((page, index) => (
                            <li key={index} className="bg-slate-150 dark:bg-navy-500">
                                <a href="#" onClick={() => setCurrentPage(page)}
                                   className={(page != currentPage ? "flex h-8 min-w-[2rem] items-center justify-center rounded-lg px-3 leading-tight transition-colors hover:bg-slate-300 focus:bg-slate-300 active:bg-slate-300/80 dark:hover:bg-navy-450 dark:focus:bg-navy-450 dark:active:bg-navy-450/90"
                                           : "flex h-8 min-w-[2rem] items-center justify-center rounded-lg bg-primary px-3 leading-tight text-white transition-colors hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90")
                                       + ((page > currentPage + 2) && (page != Math.ceil(chats?.length / itemsPerPage) - 1) ? " hidden" : "")
                                       + ((page < currentPage - 2) && (page != 0) ? " hidden" : "")}>
                                    {page}
                                </a>
                                <a href="#"
                                   className={"flex h-8 min-w-[2rem] items-center justify-center rounded-lg px-3 leading-tight transition-colors hover:bg-slate-300 focus:bg-slate-300 active:bg-slate-300/80 dark:hover:bg-navy-450 dark:focus:bg-navy-450 dark:active:bg-navy-450/90"
                                       + (((page < currentPage - 2) && (page == 1)) ? " " : " hidden")}>
                                    {"..."}
                                </a>
                                <a href="#"
                                   className={"flex h-8 min-w-[2rem] items-center justify-center rounded-lg px-3 leading-tight transition-colors hover:bg-slate-300 focus:bg-slate-300 active:bg-slate-300/80 dark:hover:bg-navy-450 dark:focus:bg-navy-450 dark:active:bg-navy-450/90"
                                       + (((page > currentPage + 2) && (page == Math.ceil(chats.length / itemsPerPage) - 2)) ? " " : " hidden")}>
                                    {"..."}
                                </a>
                            </li>)
                        )}
                        <li className="rounded-r-lg bg-slate-150 dark:bg-navy-500">
                            <a
                                href="#"
                                onClick={(event) => (currentPage < (Math.ceil(chats.length / itemsPerPage)) ?
                                    setCurrentPage((prev) => prev + 1) :
                                    event.preventDefault())}
                                className="flex h-8 w-8 items-center justify-center rounded-lg text-slate-500 transition-colors hover:bg-slate-300 focus:bg-slate-300 active:bg-slate-300/80 dark:text-navy-200 dark:hover:bg-navy-450 dark:focus:bg-navy-450 dark:active:bg-navy-450/90"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-4 w-4"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M9 5l7 7-7 7"
                                    />
                                </svg>
                            </a>
                        </li>
                    </ol>
                </div>
                <div
                    className="flex flex-col justify-between space-y-4 px-4 py-4 sm:flex-row sm:items-center sm:space-y-0 sm:px-5"
                >
                    <div className="flex items-center space-x-2 text-xs+">
                        <label className="block">
                            <select value={itemsPerPage}
                                    onChange={(e) => handleChangeItemsPerPage(parseInt(e.target.value))}
                                    className="form-select rounded-full border border-slate-300 bg-white px-2 py-1 pr-6 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:bg-navy-700 dark:hover:border-navy-400 dark:focus:border-accent">
                                <option>5</option>
                                <option>10</option>
                                <option>20</option>
                            </select>
                        </label>
                    </div>
                    <div
                        className="text-xs+">{(currentPage - 1) * itemsPerPage + 1} - {Math.min(currentPage * itemsPerPage, chats.length)} of {chats?.length} entries
                    </div>
                </div>
            </div>
        </div>
        <div
            className="modal fixed inset-0 z-[100] flex flex-col items-center justify-center overflow-hidden px-4 py-6 sm:px-5"
            id="deleteChatModal"
            role="dialog"
        >
            <div className="modal-overlay absolute inset-0 bg-slate-900/60"></div>
            <div
                className="modal-content scrollbar-sm relative flex max-w-lg flex-col items-center overflow-y-auto rounded-lg bg-white px-4 py-10 text-center dark:bg-navy-700 sm:px-5"
            >
                <img
                    className="mx-auto h-16 w-16"
                    src={process.env.PUBLIC_URL + '/images/buttons/delete.png'}
                    alt="logo"
                />

                <div className="mt-4">
                    <h2 className="text-2xl text-slate-700 dark:text-navy-100">
                        Delete
                    </h2>
                    <p className="mt-2">
                        Are you sure you want to delete chat?
                    </p>
                    <div className="space-x-2 text-right mt-4">
                        <button
                            data-close-modal
                            onClick={handleDeleteChat}
                            className="btn min-w-[7rem] rounded-full bg-error font-medium text-white hover:bg-error-focus focus:bg-error-focus active:bg-error-focus/90 dark:bg-orange dark:hover:bg-orange-focus dark:focus:bg-orange-focus dark:active:bg-orange/90"
                        >
                            Delete
                        </button>
                        <button
                            data-close-modal
                            className="btn min-w-[7rem] rounded-full border border-slate-300 font-medium text-slate-800 hover:bg-slate-150 focus:bg-slate-150 active:bg-slate-150/80 dark:border-navy-450 dark:text-navy-50 dark:hover:bg-navy-500 dark:focus:bg-navy-500 dark:active:bg-navy-500/90"
                            onClick={(event) => event.preventDefault()}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div
            className="modal fixed inset-0 z-[100] flex flex-col items-center justify-center overflow-hidden px-4 py-6 sm:px-5"
            id="infoChatModal"
            role="dialog"
        >
            <div className="modal-overlay absolute inset-0 bg-slate-900/60"></div>
            <div
                className="modal-content scrollbar-sm relative flex w-[80%] flex-col items-center overflow-y-auto rounded-lg bg-white px-4 py-10 text-center dark:bg-navy-700 sm:px-5"
            >
                <img
                    className="mx-auto h-16 w-16"
                    src={process.env.PUBLIC_URL + '/images/buttons/info.png'}
                    alt="logo"
                />

                <div className="w-full mt-4">
                    <h2 className="text-2xl text-slate-700 dark:text-navy-100">
                        Keywords
                    </h2>
                    <div id="no-keyword"></div>
                    <div id="chatsidebar-wordcloud-canvas" className="w-full"></div>
                    <div className="space-x-2 text-right mt-4">
                        <button
                            data-close-modal
                            onClick={(event) => event.preventDefault()}
                            className="btn min-w-[7rem] rounded-full bg-error font-medium text-white hover:bg-error-focus focus:bg-error-focus active:bg-error-focus/90 dark:bg-orange dark:hover:bg-orange-focus dark:focus:bg-orange-focus dark:active:bg-orange/90"
                        >
                            OK
                        </button>
                        <button
                            data-close-modal
                            className="btn min-w-[7rem] rounded-full border border-slate-300 font-medium text-slate-800 hover:bg-slate-150 focus:bg-slate-150 active:bg-slate-150/80 dark:border-navy-450 dark:text-navy-50 dark:hover:bg-navy-500 dark:focus:bg-navy-500 dark:active:bg-navy-500/90"
                            onClick={(event) => event.preventDefault()}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <Dialog
        open={createChatModal}
        onClose={() => handleCloseCreateChatModel()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <div className="flex w-full justify-between bg-slate-200 px-4 py-3 dark:bg-navy-800 sm:px-5">
            <h1 className="text-base font-medium text-slate-700 dark:text-navy-100">Start a chat</h1>
            <button
                onClick={(e) => handleCloseCreateChatModel(e)}
                className="btn dark:text-navy-100 -mr-1.5 h-7 w-7 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25"
            >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-4.5 w-4.5" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                </svg>
            </button>
        </div>
        <div className="relative flex w-full max-w-lg origin-top flex-col overflow-hidden bg-white dark:bg-navy-700">
            <form className="scrollbar-sm dark:text-navy-100 overflow-y-auto px-4 py-4 sm:px-5">
                <p>Specify your chat's title and type.</p>
                <div className="mt-4 space-y-4">
                    <label className={"block" + (course?.assistant_selection === 'random' ? ' hidden' : '')}>
                        <span>Assistant:</span>
                        <Select
                            styles={CustomStyles}
                            loadingMessage={() => 'Loading ...'}
                            closeMenuOnSelect={true}
                            value={selectedAssistant}
                            isMulti={false}
                            components={{ Option }}
                            menuPortalTarget={document.body}
                            options={assistants}
                            getOptionLabel={(option) => option?.name}
                            getOptionValue={(option) => option?.id}
                            onChange={selectAssistant}
                        />
                    </label>
                    <label className="block">
                        <span>Title:</span>
                        <input
                            className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                            placeholder="Your Chat Title"
                            name="title"
                            value={inputs.title}
                            onChange={handleChange}
                            onKeyDown={keyupCreateHandler}
                            type="text"
                        />
                    </label>
                    <div className="space-x-2 text-right">
                        <button
                            className="btn min-w-[7rem] rounded-full border border-slate-300 font-medium text-slate-800 hover:bg-slate-150 focus:bg-slate-150 active:bg-slate-150/80 dark:border-navy-450 dark:text-navy-50 dark:hover:bg-navy-500 dark:focus:bg-navy-500 dark:active:bg-navy-500/90"
                            onClick={(event) => handleCloseCreateChatModel(event)}
                        >
                            Cancel
                        </button>
                        <button
                            onClick={submitChat}
                            className="btn min-w-[7rem] rounded-full bg-primary font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90"
                        >
                            Start
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </Dialog>
        <div
            id="updateChatModal"
            className="modal modal-scale fixed inset-0 z-[100] flex flex-col items-center justify-center overflow-hidden px-4 py-6 sm:px-5"
            role="dialog"
        >
            <div className="modal-overlay absolute inset-0 bg-slate-900/60"></div>
            <div
                className="modal-content relative flex w-full max-w-lg origin-top flex-col rounded-lg bg-white dark:bg-navy-700"
            >
                <div
                    className="flex justify-between rounded-t-lg bg-slate-200 px-4 py-3 dark:bg-navy-800 sm:px-5"
                >
                    <h3 className="text-base font-medium text-slate-700 dark:text-navy-100">
                        Edit the chat
                    </h3>
                    <button
                        data-close-modal
                        className="btn -mr-1.5 h-7 w-7 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-4.5 w-4.5"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth="2"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M6 18L18 6M6 6l12 12"
                            />
                        </svg>
                    </button>
                </div>
                <form className="scrollbar-sm overflow-y-auto px-4 py-4 sm:px-5"
                      onKeyUp={(event) => keyUpEditHandler(event)}>
                    <p>
                        Specify your chat's title and type.
                    </p>
                    <div className="mt-4 space-y-4">
                        <label className={"block" + (course?.assistant_selection === 'random' ? ' hidden' : '')}>
                            <span>Assistant:</span>
                            <div id="dropdown-wrapper-edit">
                                <button
                                    className="popper-ref mt-1.5 w-full text-left rounded-lg border border-slate-300 bg-white px-3 py-2 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:bg-navy-700 dark:hover:border-navy-400 dark:focus:border-accent"
                                    onClick={(event) => event.preventDefault()}
                                >
                                    <span>{selectedAssistant.name}</span>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-4 w-4 transition-transform duration-200 float-right"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M19 9l-7 7-7-7"
                                        />
                                    </svg>
                                </button>
                                <div className="popper-root w-full pr-10">
                                    <div
                                        className="popper-box w-full rounded-md border border-slate-150 bg-white dark:border-navy-600 dark:bg-navy-700"
                                    >
                                        <ul className="my-2">
                                            {assistantItems}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </label>
                        <label className="block">
                            <span>Title:</span>
                            <input
                                className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                placeholder="Your Chat Title"
                                name="title"
                                value={inputs.title}
                                onChange={handleChange}
                                type={"text"}
                            />
                        </label>
                        <div className="space-x-2 text-right">
                            <button
                                data-close-modal
                                className="btn min-w-[7rem] rounded-full border border-slate-300 font-medium text-slate-800 hover:bg-slate-150 focus:bg-slate-150 active:bg-slate-150/80 dark:border-navy-450 dark:text-navy-50 dark:hover:bg-navy-500 dark:focus:bg-navy-500 dark:active:bg-navy-500/90"
                                onClick={(event) => event.preventDefault()}
                            >
                                Cancel
                            </button>
                            <button
                                data-close-modal
                                onClick={updateChat}
                                className="btn min-w-[7rem] rounded-full bg-primary font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90"
                            >
                                Update
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>

        {/* <div
            id="courseFormModal"
            className="modal modal-scale fixed inset-0 z-[100] flex flex-col items-center justify-center overflow-hidden px-4 py-6 sm:px-5"
            role="dialog"
        >
            <div className="modal-overlay absolute inset-0 bg-slate-900/60"></div>
            <div
                className="modal-content relative flex w-full max-w-lg origin-top flex-col overflow-hidden rounded-lg bg-white dark:bg-navy-700"
            >
                <div
                    className="flex justify-between rounded-t-lg bg-slate-200 px-4 py-3 dark:bg-navy-800 sm:px-5"
                >
                    <h3 className="text-base font-medium text-slate-700 dark:text-navy-100">
                        Please fill this form to create a chat
                    </h3>
                    <button
                        data-close-modal
                        className="btn -mr-1.5 h-7 w-7 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-4.5 w-4.5"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth="2"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M6 18L18 6M6 6l12 12"
                            />
                        </svg>
                    </button>
                </div>
                {courseFormError.customized_error_code !== -1 ?
                    <span className="text-center text-tiny+ text-error">{courseFormError.message}</span>
                    : ""
                }
                <form className="px-4 py-4 sm:px-5 max-h-[80%] scrollbar-sm overflow-y-auto">
                    <div className="grid grid-cols-2 gap-4 mt-4">
                        <label className="block">
                            <span>What is your sex?</span>
                            <select name="sex"
                                    onChange={handleCourseFormInputChange}
                                    value={courseFormInputs.sex}
                                    className="form-select mt-1.5 w-full rounded-lg border border-slate-300 bg-white px-3 py-2 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:bg-navy-700 dark:hover:border-navy-400 dark:focus:border-accent"
                            >
                                <option hidden disabled selected value=""> -- select an option --</option>
                                <option value="male">Male</option>
                                <option value="female">Female</option>
                                <option value="noanswer">Prefer not to answer</option>
                            </select>
                        </label>
                        <label className="block">
                            <span>What is your age?</span>
                            <input
                                className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                placeholder="Your Age"
                                name="age"
                                value={courseFormInputs.age}
                                onChange={handleCourseFormInputChange}
                                type={"text"}
                            />
                        </label>
                        <label className="block">
                            <span>What is your gender you identified with?</span>
                            <select name="gender" onChange={handleCourseFormInputChange}
                                    value={courseFormInputs.gender}
                                    className="form-select mt-1.5 w-full rounded-lg border border-slate-300 bg-white px-3 py-2 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:bg-navy-700 dark:hover:border-navy-400 dark:focus:border-accent"
                            >
                                <option hidden disabled selected value=""> -- select an option --</option>
                                <option value="male">Male</option>
                                <option value="female">Female</option>
                                <option value="others">Others</option>
                                <option value="noanswer">Prefer not to answer</option>
                            </select>
                        </label>
                        <label className="block">
                            <span>Please share your GPA to the best of your recollection.</span>
                            <input
                                className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                placeholder="Your GPA"
                                name="gpa"
                                value={courseFormInputs.gpa}
                                onChange={handleCourseFormInputChange}
                                type={"text"}
                            />
                        </label>
                    </div>
                    <div className="grid grid-cols-1 gap-4 mt-2">
                        <label className="block">
                            <span>Which level below do you believe best describes your family’s economic status?</span>
                            <select name="quantile" onChange={handleCourseFormInputChange}
                                    value={courseFormInputs.quantile}
                                    className="form-select mt-1.5 w-full rounded-lg border border-slate-300 bg-white px-3 py-2 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:bg-navy-700 dark:hover:border-navy-400 dark:focus:border-accent"
                            >
                                <option hidden disabled selected value=""> -- select an option --</option>
                                <option value="bottom_25">Bottom 25%</option>
                                <option value="26_50">26-50%</option>
                                <option value="51_75">51-75%</option>
                                <option value="76_90">76-90%</option>
                                <option value="top_90">Top 10%</option>
                            </select>
                        </label>
                        <label className="block">
                            <span>How knowledgeable do you consider yourself regarding AI and its applications, such as ChatGPT?</span>
                            <select name="knowledge" onChange={handleCourseFormInputChange}
                                    value={courseFormInputs.knowledge}
                                    className="form-select mt-1.5 w-full rounded-lg border border-slate-300 bg-white px-3 py-2 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:bg-navy-700 dark:hover:border-navy-400 dark:focus:border-accent"
                            >
                                <option hidden disabled selected value=""> -- select an option --</option>
                                <option value="Not_knowledgeable">Not knowledgeable at all</option>
                                <option value="Slightly_knowledgeable">Slightly knowledgeable</option>
                                <option value="Moderately_knowledgeable">Moderately knowledgeable</option>
                                <option value="Very_knowledgeable">Very knowledgeable</option>
                                <option value="Extremely_knowledgeable">Extremely knowledgeable</option>
                            </select>
                        </label>
                        <label className="block">
                            <span>How frequently do you use ChatGPT? If you have never used it, please select 'Never'</span>
                            <select name="frequency" onChange={handleCourseFormInputChange}
                                    value={courseFormInputs.frequency}
                                    className="form-select mt-1.5 w-full rounded-lg border border-slate-300 bg-white px-3 py-2 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:bg-navy-700 dark:hover:border-navy-400 dark:focus:border-accent"
                            >
                                <option hidden disabled selected value=""> -- select an option --</option>
                                <option value="never">Never</option>
                                <option value="once_twice">Only once or twice</option>
                                <option value="once_few_weeks">Once every few weeks</option>
                                <option value="everyweek">Every week</option>
                                <option value="everyday">Every day</option>
                            </select>
                        </label>
                        <label className="block">
                            <span>how useful do you find AI tools like ChatGPT?</span>
                            <select name="usefulness" onChange={handleCourseFormInputChange}
                                    value={courseFormInputs.usefulness}
                                    className="form-select mt-1.5 w-full rounded-lg border border-slate-300 bg-white px-3 py-2 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:bg-navy-700 dark:hover:border-navy-400 dark:focus:border-accent"
                            >
                                <option hidden disabled selected value=""> -- select an option --</option>
                                <option value="Not_Useful">Not Useful at All</option>
                                <option value="Slightly_Useful">Slightly Useful</option>
                                <option value="Moderately_Useful">Moderately Useful</option>
                                <option value="Very_Useful">Very Useful</option>
                                <option value="Extremely_Useful">Extremely Useful</option>
                            </select>
                        </label>
                        <label className="block">
                            <span>What are your feelings about using an AI learning platform like this website?</span>
                            <textarea
                                className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                placeholder="Your Content"
                                name="feelings"
                                value={courseFormInputs.feelings}
                                onChange={handleCourseFormInputChange}
                            />
                        </label>
                    </div>
                </form>
                <div className="my-4 h-px  bg-slate-200 dark:bg-navy-500"></div>
                <div className="space-x-2 text-right pr-4 pb-4">
                    <button
                        className="btn min-w-[7rem] rounded-full border border-slate-300 font-medium text-slate-800 hover:bg-slate-150 focus:bg-slate-150 active:bg-slate-150/80 dark:border-navy-450 dark:text-navy-50 dark:hover:bg-navy-500 dark:focus:bg-navy-500 dark:active:bg-navy-500/90"
                        onClick={(event) => {
                            event.preventDefault();
                            courseFormModal.close();
                        }}
                    >
                        Cancel
                    </button>
                    <button
                        onClick={submitCourseForm}
                        className="btn min-w-[7rem] rounded-full bg-primary font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90"
                    >
                        Update
                    </button>
                </div>
            </div>
        </div> */}

        <Outlet send_message={true}/>
    </div>
}

export default ChatSidebarMenu;