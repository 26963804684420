import React, {useEffect} from "react";

import {useLayoutContext} from "../../layout/layoutProvider";
import {useLocation} from "react-router-dom";
import {openCloseSidebarPanel} from "../../utils/helpers";
import {logotypeWithIcon} from "../../utils/svgs";

const logoColor = "#21cbaf";

const AboutPage = () => {
    const location = useLocation()
    const {setLoader, setSidebarSelectedMenuItem} = useLayoutContext();

    function updateMenu() {
        if (location.pathname.includes('about')) {
            setSidebarSelectedMenuItem('about');
        }
    }

    useEffect(() => {
        setLoader(false);
        updateMenu();
        openCloseSidebarPanel(false);
    }, [location]);

    return (
        <main className="main-content h-100vh mt-0">
            <div className="scrollbar-sm grow px-[calc(var(--margin-x)-.5rem)] mt-20 transition-all duration-[.25s]">
                <div className="items-center space-x-4 py-5 lg:py-6">
                    <div className="mt-12 mr-4 ml-4">
                        <div
                            className="rounded-2xl bg-white p-3 text-slate-700 shadow-sm dark:bg-navy-700 dark:text-navy-100">
                            <p><strong>Welcome to JOURNiCRAFT!</strong></p>
                            <br/>
                            <p><strong>JOURNiCRAFT</strong> stems from the collective vision of a team of technologists
                                and computer scientists dedicated to advancing technology in education. We're committed
                                to reshaping the learning landscape by seamlessly integrating state-of-the-art tools
                                into academic settings.</p>
                            <p>As an independent entity, it's crucial to highlight
                                that <strong>JOURNiCRAFT</strong> operates autonomously, without direct affiliation to
                                any academic institution. However, we're thrilled to announce our collaboration with
                                Assistant Professor <strong>Mahdi Hashemian</strong> at Koç University. Our current
                                focus revolves around enriching the course experience.</p>
                            <p>Currently, our website is a work in progress, specifically designed to serve as a dynamic
                                platform for students to engage with ChatGPT. This innovative tool stands as a virtual
                                Teaching Assistant (TA), offering invaluable assistance in both general discussions
                                related to the course and tailored exercises.</p>
                            <p>Furthermore, students can leverage the website in two distinct ways: firstly, for general
                                discussions related to the course material, and secondly, for specific
                                assignment-related interactions. This division not only allows for exercise-specific
                                context within discussions with ChatGPT but also provides a feature enabling students to
                                upload their answers and receive feedback directly from the faculty member teaching the
                                course.</p>
                            <p>Our primary objective is to harness the immense potential of ChatGPT within the realm of
                                Management Science, leveraging its capabilities to bolster the teaching and learning
                                process.</p>
                            <p>Stay tuned as we continuously evolve and expand the functionalities of this platform,
                                consistently bringing innovative solutions to the forefront of education.</p>
                        </div>
                        <br/>
                    </div>
                    <div className="mt-12 w-auto h-24 text-center">
                        {logotypeWithIcon("h-30", "0 0 2800 300")}
                    </div>
                </div>
            </div>
        </main>
    );
}

export default AboutPage;