export const faqsList = [
    {
        question: "What is JOURNiCRAFT?",
        answer: "JOURNiCRAFT is a platform designed to help higher education teachers integrate generative AI into their courses, providing personalized learning experiences for students."
    },
    {
        question: "Who can use JOURNiCRAFT?",
        answer: "JOURNiCRAFT is designed for higher education teachers who want to enhance their teaching with generative AI."
    },
    {
        question: "What are the pricing plans for JOURNiCRAFT?",
        answer: "JOURNiCRAFT offers three pricing plans: Free Plan, Teacher Plan, and Program Plan. The Free Plan is free, the Teacher Plan costs $2 per student, and the Program Plan is priced upon contact."
    },
    {
        question: "What data is being collected? How is my data managed?",
        answer: `Your privacy matters; we collect minimal personal data (names, emails, and chat interactions) solely for the enhancement of your learning process we ensure the confidentiality of your information.
        Your data is being stored in secure servers in Germany. We will not share your data with third parties. Only your instructors and the JOURNiCRAFT team have access to your data. Students cannot see each others conversations and instructors access is limited to their respective courses. Measures are in place to maintain student identity confidentiality throughout the data collection and storage process. Your data will be deleted after the course is finished.
        By using JOURNiCRAFT, you consent to data collection and understand the inherent risks in AI-generated responses.`,
        type: "list"

    },
    {
        question: "What are the main features of JOURNiCRAFT?",
        answer: `Monitor student chats and gain insights
          Provide feedback on student chats and messages
          Redesign course plans, outlines, and structures
          Find practical examples
          Design quizzes and assignments
          Get tips on class management
          Motivate students with gamification
          Update course materials
          Create exercises and assist students with AI chat`,
        type: "list"
    },
    {
        question: "What can instructors do on the website?",
        answer: `Instructors can upload their course materials to JOURNiCRAFT, where our platform assists in redesigning courses by enhancing the course outline and structure. 
        JOURNiCRAFT also helps instructors find practical examples for their lectures and design quizzes and assignments. Additionally, instructors can monitor student chats, gain insights from these discussions, and provide feedback to encourage more effective questioning and learning. This helps students develop better learning strategies through generative AI. 
        Instructors can also create interactive exercises on JOURNiCRAFT that require students to engage in chats to find answers and submit their responses directly on the platform.
  `
    },
    {
        question: "What is the model and how to train it?",
        answer: `The model being referred to is an AI model used by JOURNiCRAFT to enhance teaching and learning experiences. This AI model can be customized based on the materials uploaded by instructors and supplemented with relevant scientific materials.
        To train a specific AI model to use in your chats, you need to upload your materials. The JOURNiCRAFT assistant considers your materials, along with specific scientific materials in the related domain, to perform tasks such as creating quiz questions or finding practical learning examples.
  `
    },
];