import {Link, useNavigate, useSearchParams} from 'react-router-dom';
import React, {useEffect, useState} from "react";

import {useAuth} from "../../utils/authProvider";

import whiteLogoImage from '../../../images/jclogow.svg';
import googleIcon from '../../../images/google.png';
import githubIcon from '../../../images/github.png';
import FetchClient from "../../utils/fetchClient";
import Application from "../../app";
import {useLayoutContext} from "../../layout/layoutProvider";

const logoColor = "#21cbaf";

const ForgetPassPage = () => {
    const navigate = useNavigate();
    const {setLoader} = useLayoutContext();
    const [searchParams, setSearchParams] = useSearchParams();
    const key = searchParams.get("key");
    const email = searchParams.get("email");

    const [passInputs, setPassInputs] =
        useState({oldPass: "", newPass: "", newPassRepeat: ""});
    const [change_pass_response_error, setChangePassResponseError] = useState();

    const handleUserPasswordChange = (event) => {
        setChangePassResponseError("");
        const {name, value} = event.target;
        setPassInputs(values => ({...values, [name]: value}))
    }

    function submitUserPassword(event) {
        event.preventDefault();
        if (passInputs.newPass === passInputs.newPassRepeat) {
            setLoader(true);
            FetchClient({
                method: 'put',
                url: 'users/change_forget_pass/',
                params: {
                    email: email,
                    old_hashed_pass: key,
                    new_pass: passInputs.newPass
                }
            }).then(response => {
                window.$notification({
                    text: 'Password changed successfully!',
                    variant: 'primary',
                    position: 'center-top'
                })
                setLoader(false);
                setPassInputs({oldPass: "", newPass: "", newPassRepeat: ""});
                navigate("/", {replace: true});
            }).catch((error) => {
                setLoader(false);
                if (error.response) {
                    setChangePassResponseError(error.response.data.detail);
                }
            });
        } else {
            setChangePassResponseError("New password and repeat password are not the same.");
        }
    }

    return <div className="min-h-100vh flex grow bg-slate-50 dark:bg-navy-900">
        <main className="grid w-full grow grid-cols-1 place-items-center">
            <form className="w-full max-w-[26rem] p-4 sm:px-5">
                <div className="text-center">
                    <Link to="/">
                        <svg
                            className="h-20 w-20 mx-auto"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 500 500"
                        >
                            <g>
                                <path fill={logoColor}
                                      d="M259.1,318.6c6.2-14.9,9.2-30.9,9.2-47.8v-7.7c-0.8-6.2-1.2-12.7-1.2-19.4c0-6.5,0.4-12.9,1.2-19V120.4   c-8,5.2-15.1,11.1-21.6,17.6c-13.2,13.4-23.4,29.3-30.3,47.6c-6.9,18.3-10.5,37.8-10.5,58.6s3.5,40.3,10.5,58.6   c6.3,16.4,15.1,30.9,26.5,43.4C249.4,338,254.9,328.7,259.1,318.6z"/>
                                <path fill={logoColor}
                                      d="M206.6,246.7V272c0,20.2-5.5,36.6-16.3,49.5c-10.8,12.8-27.7,19.2-50.7,19.2c-5,0-9.1-0.2-12.5-0.7   c-1.3-0.2-2.6-0.4-3.9-0.6c1.7,5.9,2.5,12.1,2.5,18.6c0,8.3-1.4,16.1-4,23.3c-1.3,3.5-2.8,6.7-4.6,9.8v0.9   c4.3,0.6,7.9,1.2,10.7,1.7c2.8,0.5,7.5,0.7,13.8,0.7c19.3,0,36.6-3.1,52-9.7c15.3-6.4,28.4-15.1,39.2-26.1c10.8-11,19.1-24,25-39   c5.8-14.9,8.7-30.9,8.7-47.8v-25.3h-59.9V246.7z"/>
                                <g>
                                    <path fill={logoColor}
                                          d="M4.6,357.7c0-8.2,1.4-16,4-23.2c2.6-7.4,6.4-13.6,11.4-19s11.1-9.7,18.5-12.8c7.4-3.1,15.5-4.7,24.8-4.7    s17.5,1.6,24.8,4.7c7.3,3.2,13.4,7.4,18.5,12.8c5,5.4,8.8,11.8,11.4,19c2.6,7.4,4,15,4,23.2c0,8.3-1.4,16.1-4,23.3    c-2.6,7.2-6.4,13.5-11.4,18.9c-5,5.4-11.1,9.7-18.5,12.8s-15.5,4.7-24.8,4.7s-17.5-1.6-24.8-4.7c-7.3-3.1-13.4-7.4-18.5-12.8    c-5-5.4-8.8-11.7-11.4-18.9C5.8,373.8,4.6,366,4.6,357.7z M27.6,357.7c0,5.2,0.6,10.2,2,14.9c1.4,4.7,3.5,8.7,6.3,12.2    c2.8,3.5,6.6,6.2,11,8.2c4.5,2,9.9,2.9,16.2,2.9s11.7-0.9,16.2-2.9c4.5-2,8.2-4.7,11-8.2c2.8-3.5,5-7.6,6.3-12.2    c1.4-4.7,2-9.7,2-14.9s-0.6-10.2-2-14.9c-1.4-4.7-3.5-8.7-6.3-12.2c-2.8-3.5-6.6-6.2-11-8.2c-4.5-2-9.9-2.9-16.2-2.9    s-11.7,0.9-16.2,2.9s-8.2,4.7-11,8.2c-2.8,3.5-5,7.6-6.3,12.2C28.3,347.4,27.6,352.5,27.6,357.7z"/>
                                </g>
                                <path fill={logoColor} fillOpacity=".5"
                                      d="M503.4,107.4c-2.6-7.4-6.4-13.7-11.4-19c-5-5.4-11.1-9.7-18.5-12.8c-7.4-3.1-15.5-4.7-24.8-4.7   c-9.2,0-17.5,1.6-24.8,4.7c-7.4,3.2-13.4,7.4-18.5,12.8c-5,5.4-8.8,11.8-11.4,19c-2.6,7.3-4,15-4,23.2c0,8.3,1.4,16.1,4,23.3   c2.6,7.2,6.4,13.5,11.4,18.9s11.1,9.7,18.5,12.8c7.3,3.1,15.5,4.7,24.8,4.7c9.2,0,17.5-1.6,24.8-4.7c7.4-3.2,13.4-7.4,18.5-12.8   c5-5.4,8.8-11.7,11.4-18.9c2.6-7.2,4-15,4-23.3C507.3,122.4,506.1,114.6,503.4,107.4z M482.3,145.5c-1.4,4.7-3.5,8.7-6.3,12.2   c-2.8,3.5-6.6,6.2-11,8.2c-4.5,2-9.9,2.9-16.2,2.9c-6.3,0-11.7-0.9-16.2-2.9c-4.5-2-8.2-4.7-11-8.2c-2.8-3.5-5-7.6-6.3-12.2   c-1.4-4.7-2-9.7-2-14.9c0-5.3,0.6-10.2,2-14.9s3.5-8.7,6.3-12.2c2.8-3.5,6.6-6.2,11-8.2c4.5-2,9.9-2.9,16.2-2.9   c6.3,0,11.7,0.9,16.2,2.9s8.2,4.7,11,8.2c2.8,3.5,5,7.6,6.3,12.2c1.4,4.7,2,9.7,2,14.9C484.3,135.8,483.6,140.9,482.3,145.5z"/>
                                <g>
                                    <path fill={logoColor} fillOpacity=".5"
                                          d="M271.4,220.6c0.7-5.1,1.8-10.1,3.1-14.8c3.2-11.3,8.4-21.3,15.8-29.8c7.5-8.5,17.3-15.1,29.6-19.9    c12.4-4.8,27.6-7.2,45.7-7.2c8.3,0,16.5,0.3,24.7,1.1c-2.5-6.5-3.9-13.4-3.9-20.8c0-12.8,4.2-24.7,11.4-34.4    c-11.6-1.1-23.3-1.6-35.2-1.6c-24.4,0-46,3.9-65,11.8c-9.6,4-18.3,8.6-26.4,14v101.8H271.4z"/>
                                    <path fill={logoColor} fillOpacity=".5"
                                          d="M411.6,333.6c-15.1,2.4-30.5,3.6-45.9,3.6c-18.1,0-33.3-2.4-45.7-7.2s-22.3-11.4-29.6-20    c-7.5-8.5-12.7-18.5-15.8-30c-1.4-4.8-2.3-9.9-3.1-15.1v8.1c0,17-2.9,32.9-8.9,47.8c-3.8,9.5-8.6,18.2-14.5,26.1    c0.6,0.7,1.4,1.5,2.1,2.2c12.8,13.4,28.8,24.1,47.7,31.9c18.9,7.9,40.5,11.8,65,11.8c15.4,0,30.7-0.8,45.5-2.6    c14.8-1.8,28.4-4.4,40.5-8V323C439,327.7,426.7,331.1,411.6,333.6z"/>
                                </g>
                            </g>
                        </svg>
                    </Link>
                    <div className="mt-4">
                        <h2
                            className="text-2xl font-semibold text-slate-600 dark:text-navy-100"
                        >
                            JOURNiCRAFT
                        </h2>
                        <p className="text-slate-400 dark:text-navy-300">
                            Enter new password for your account
                        </p>
                    </div>
                </div>
                <div className="card mt-5 rounded-lg p-5 lg:p-7">
                    <label className="block hidden">
                        <span>Old Password:</span>
                        <input
                            className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                            placeholder="Old Password"
                            name="oldPass"
                            value={passInputs.oldPass}
                            onChange={handleUserPasswordChange}
                            type={"password"}
                        />
                    </label>
                    <label className="mt-4 block">
                        <span>New Password:</span>
                        <input
                            className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                            placeholder="New Password"
                            name="newPass"
                            value={passInputs.newPass}
                            onChange={handleUserPasswordChange}
                            type={"password"}
                        />
                    </label>
                    <label className="mt-4 block">
                        <span>Repeat New Password:</span>
                        <input
                            className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                            placeholder="Repeat New Password"
                            name="newPassRepeat"
                            value={passInputs.newPassRepeat}
                            onChange={handleUserPasswordChange}
                            type={"password"}
                        />
                    </label>
                    {change_pass_response_error !== "" ?
                        <span className="text-center text-tiny+ text-error">{change_pass_response_error}</span>
                        : ""
                    }
                    <div className="space-x-2 mt-8 text-right">
                        <Link to="/"
                              className="text-primary transition-colors hover:text-primary-focus dark:text-accent-light dark:hover:text-accent">
                            <button
                                data-close-modal
                                className="btn min-w-[7rem] rounded-full border border-slate-300 font-medium text-slate-800 hover:bg-slate-150 focus:bg-slate-150 active:bg-slate-150/80 dark:border-navy-450 dark:text-navy-50 dark:hover:bg-navy-500 dark:focus:bg-navy-500 dark:active:bg-navy-500/90"
                            >
                                Cancel
                            </button>
                        </Link>
                        <button
                            onClick={submitUserPassword}
                            className="btn min-w-[7rem] rounded-full bg-primary font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90"
                        >
                            Set New Password
                        </button>
                    </div>
                </div>
                <div
                    className="mt-8 flex justify-center text-xs text-slate-400 dark:text-navy-300"
                >
                    <a href="#">Privacy Notice</a>
                    <div className="mx-3 my-1 w-px bg-slate-200 dark:bg-navy-500"></div>
                    <a href="#">Term of service</a>
                </div>
            </form>
        </main>
    </div>
}

export default ForgetPassPage;