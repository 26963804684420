import {RouterProvider, createBrowserRouter, Navigate, useLocation} from "react-router-dom";

import {ProtectedRoute} from "./utils/ProtectedRoute";
import DashboardPage from "./pages/dashboard/dashboardPage";
import ChatPage from "./pages/chat/chatPage";
import LoginPage from "./pages/login/loginPage";
import RegisterPage from "./pages/register/registerPage";
import {useAuth} from "./utils/authProvider";
import Chat from "./pages/chat/components/chat";
import SiteLayout from "./layout/SiteLayout";
import AboutItem from "./layout/sidebarditems/aboutItem";
import AboutPage from "./pages/about/aboutPage";
import UserListItem from "./layout/sidebarditems/userListItem";
import UserListPage from "./pages/userlist/userListPage";
import UserPage from "./pages/userlist/components/userPage";
import UserChat from "./pages/userlist/components/userChat";
import ExerciseListPage from "./pages/exercise/exerciseListPage";
import ExercisePage from "./pages/exercise/components/exercisePage";
import ForgetPassPage from "./pages/login/forgetPassPage";
import ActivateAccountPage from "./pages/register/activateAccountPage";
import Firstpage from "./pages/website/firstpage";
import CoursePage from "./pages/course/coursePage";
import PrivacyPage from "./pages/website/pages/privacy";
import TeacherMessage from "./pages/message/teacherMessage";
import StudentMessage from "./pages/message/studentMessage";
import { useEffect, useMemo, useState } from "react";
import { openCloseSidebarPanel } from "./utils/helpers";
import HistoryChat from "./pages/message/historyChat";
import ChatWizardType from "./pages/chatWizard/chatWIzardType";
import AdminDashboard from "./pages/adminDashboard/dashboard";
import WizardQuestions from "./pages/chatWizardQuestions/questions";
import WizardEmbededModels from "./pages/chatWizardEmbeddedModel/chatWizardEmbeddedModels";
import AddModel from "./pages/dashboard/addModel";
import { useCourseContext } from "./utils/courseContext";
import { useCookies } from "react-cookie";
import FetchClient from "./utils/fetchClient";
const JRoutes = (props) => {
    const store = useAuth();
    const [cookies] = useCookies();
  const user_role = localStorage.getItem('user_role');

    const routesForPublic = [
        {
            path: "/service",
            element: <div>Service Page</div>,
        },
        {
            path: "/home",
            element: <Firstpage/>
        },
        // {
        //     path: "/",
        //     element: <Navigate to="/home"/>
        // },
    ];
    const routerForTeacherOnlly = [{

        path: "/",
        element: <ProtectedRoute/>, 
        children: [
            {
                path: "/",
                element: <Navigate to="/dashboard"/>,
            },
            {
                path: "/dashboard",
                element: <DashboardPage/>,
            },
            {
                path: "/login",
                element: <Navigate to="/dashboard"/>,
            },
            {
                path: "/about",
                element: <AboutPage/>
            },

        {
            path: "/course",
            element: <CoursePage/>,
        },
        {
            path: "/add-model/:id",
            element: <AddModel/>,
        },
        {
            path: "/chats",
            element: <ChatPage/>,
            children: [
                {
                    path: "chat/:chat_id",
                    element: <Chat/>
                }
            ]
        },
        {
            path: "/user_list",
            element: <UserListPage/>,
            children: [
                {
                    path: "user/:user_id/course/:course_id",
                    element: <UserPage/>
                },
                {
                    path: "user/:user_id/chat/:chat_id",
                    element: <UserChat/>
                }
            ]
        },
        { 
            path: "/messages",
            element:<TeacherMessage />,
        },
        { 
            path: "/messages/:id",
            element:<HistoryChat />,
        },
        {
            path: "/exercise_list",
            element: <ExerciseListPage/>,
            children: [
                {
                    path: "/exercise_list/course/:course_id/exercise/:exercise_id",
                    element: <ExercisePage/>
                }
            ]
        },
    ]}
    ]
    const routesForStudentsOnly = [
        {
            path: "/",
            element: <ProtectedRoute/>, 
            children: [
                {
                    path: "/",
                    element: <Navigate to="/dashboard"/>,
                },
                {
                    path: "/dashboard",
                    element: <DashboardPage/>,
                },

                {
                    path: "/login",
                    element: <Navigate to="/dashboard"/>,
                },

                { 
                    path: "/messages",
                    element:<StudentMessage />,
                },
                {
                    path: "/messages/:id",
                    element:<HistoryChat />,
                },
                {
                    path: "/chats",
                    element: <ChatPage/>,
                    children: [
                        {
                            path: "/chats/chat/:chat_id",
                            element: <Chat/>
                        }
                    ]
                },
  
                {
                    path: "/about",
                    element: <AboutPage/>
                }
            ]
        },
        {
            path: "/home",
            element: <Firstpage/>,
        },
        {
            path: "/forget_password",
            element: <ForgetPassPage/>,
        },
        {
            path: "/privacy",
            element: <PrivacyPage/>,
        }
    ];
    const routesForAdminOnly = [
        {
            path: "/",
            element: <ProtectedRoute/>, 
            children: [
                {
                    path: "/",
                    element: <Navigate to="/admin_dashboard"/>,
                },
                {
                    path: "/login",
                    element: <Navigate to="/admin_dashboard"/>,
                },
                {
                    path: "/admin_dashboard",
                    element: <AdminDashboard/>,
                },
                {
                    path: "/home",
                    element: <Firstpage/>,
                },
                { 
                path: "/chat/wizard",
                children: [
                    {
                        path: "/chat/wizard/type",
                        element: <ChatWizardType />
                    },
                    {
                        path: "/chat/wizard/type/:id/questions",
                        element: <WizardQuestions />
                    },
                    {
                        path: "/chat/wizard/type/:id/embedded-models",
                        element: <WizardEmbededModels />
                    },
                ]
            },
    ]}]

    const routesForNotAuthenticatedOnly = [
        {
            path: "/",
            element: <SiteLayout/>,
            children: [
                {
                    path: "/",
                    element: <Firstpage/>,
                },
                {
                    path: "/home",
                    element: <Firstpage/>,
                },
                {
                    path: "/privacy",
                    element: <PrivacyPage/>,
                },
                {
                    path: "/login",
                    element: <LoginPage/>,
                },
                
                {
                    path: "/register",
                    element: <RegisterPage/>,
                },
                {
                    path: "/forget_password",
                    element: <ForgetPassPage/>,
                },
                {
                    path: "/activate_account",
                    element: <ActivateAccountPage/>,
                }
            ]
        }
    ];
    const router = useMemo(() => {
        
        return createBrowserRouter([
            ...routesForPublic,
            ...(!cookies.token ? routesForNotAuthenticatedOnly : []),
            ...(user_role === "teacher" || user_role === "human_teacher_assistant" ? routerForTeacherOnlly : []) ,
            ...(user_role === "student" ? routesForStudentsOnly : []),
            ...(user_role === "admin" ? routesForAdminOnly : [])
        ]);
    }, [user_role]);



    // Provide the router configuration using RouterProvider
    return <RouterProvider router={router}/>;
};

export default JRoutes;
