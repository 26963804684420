import { Modal } from "@mui/material";

export default function CustomModal({ openModal, onClose, children , customStyle }) {
    return (
        <Modal
            open={openModal}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className=" fixed inset-0 z-[100] flex flex-col items-center justify-center overflow-hidden px-4 py-6 sm:px-5"
        >
             <div className={`${customStyle && customStyle } bg-white`}>
            {children}</div>
        </Modal>
    );
}