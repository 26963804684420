import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import TabPanel from '../../components/tab_pannel';
import userIcon from '../../../images/user-black.png';
import ChatMessage from "../chat/components/chatMessage";
import grey from '@mui/material/colors/grey';
import { FormControl, FormControlLabel, Grid, Radio, RadioGroup } from '@mui/material';
import SimpleChat from './simpleChat';
import { useState } from 'react';
import FetchClient from "../../utils/fetchClient";
import * as yup from 'yup';
import { useLayoutContext } from '../../layout/layoutProvider';
import Dialog from '@mui/material/Dialog';
import Chat from '../chat/components/chat';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../utils/authProvider';


const AddModel = (props) => {
    const [model, setModel] = useState("");
    const navigate = useNavigate();

    const [embededModelCommand, setEmbededModelCommand] = useState("");
  const [embededModelName, setEmbededModelName] = useState("");
  const [errorsList, setErrorsList] = useState([]);
  const {setLoader, setSidebarSelectedMenuItem} = useLayoutContext();
  const [modelCreated, setModelCreated] = useState(false);
  const store = useAuth();
  const courseId = store?.stores?.course?.id;
  const user = store?.stores?.user;
  const [file,setFile] = useState("");
    const postNewModel = async () =>{
        const schema = yup.object().shape({
            embededModelName:  yup.string().required("Name is required !"), 
        });
        try {
            await schema.validate( {embededModelName} , { abortEarly: false });  
            setLoader(true);
            FetchClient({
              method: 'post',
              url: 'chat/wizard/embedding/model/create',
              params:{course_id : courseId},
              data: {
                  owner: user?.name,
                  name: embededModelName,
                  chat_type_id : props.wizard.id,
                  course_id: courseId         
              }
          }).then(response => {
            setEmbededModelCommand("");
            setEmbededModelName("");
            setModelCreated(true);
            setModel(response?.data);
            
            setErrorsList([]);
            setLoader(false);
            window.$notification({
              text: 'Your Model is added !',
              variant: 'success',
              position: 'center-top'
          })
          }).catch((error)=>{
              window.$notification({
              text: 'Oops! There was an error',
              variant: 'error',
              position: 'center-top'
          });
          })
        } catch (err) {
            setErrorsList(err.inner);
        }
      
      };
      const handleUploadFile =  async () =>{
        const schema = yup.object().shape({
            file: yup.string().required("File is required !"), 
        });
        try {
            await schema.validate( {file} , { abortEarly: false });  
            setLoader(true);
            const formData = new FormData();
            formData.append('file', file);
            window.$notification({
                text: 'This may take some time please wait',
                variant: 'error',
                position: 'center-top'});
            FetchClient({
              method: 'post',
              url: 'chat/wizard/embedding/model/file/add',
              params:{
                chat_wizard_embedding_model_id: model?.id,
                course_id: courseId
              },
              headers: {
                'Content-Type': 'multipart/form-data'
              },
              data: formData          
          }).then(response => {
            setFile("");
            setLoader(false);
            setErrorsList([]);
            window.$notification({
              text: 'Your file is added !',
              variant: 'success',
              position: 'center-top'
          })
          }).catch((error)=>{
              window.$notification({
              text: 'Oops! There was an error',
              variant: 'error',
              position: 'center-top'
          });
          })
        } catch (err) {
            setErrorsList(err.inner);
        }

    };
    return(
        <>                            
                <div className="flex w-full justify-between rounded-t-lg bg-slate-200 dark:bg-navy-800 sm:px-5">
                <h1 className="text-base font-medium text-slate-700 dark:text-navy-100 p-4">Add model </h1 >

            </div>
            <div className="relative flex w-full mt-20 origin-top flex-col overflow-hidde dark:bg-navy-700">
                <form  className="overflow-y-auto ml-16 mr-28 sm:px-5">
            <button 
                    onClick={()=> props.back(false)}
                    className="btn min-w-[7rem] rounded-full bg-primary font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90"
                      ><svg width="20px" height="25px" fill="#ffffff" viewBox="0 0 24 24" id="left-arrow" xmlns="http://www.w3.org/2000/svg" class="icon line"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path id="primary" d="M21,12H3M6,9,3,12l3,3" 
                     stroke="#ffffff"></path></g></svg>
                        <span>
                            Go back
                        </span>
                </button>
                    <div className="mt-4  space-y-4">
                        <label className="block">
                            <span>Name:</span>
                            <input
                                className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                name="name"
                                disabled={model?.name}
                                value={model?.name ? model?.name : embededModelName}
                                onChange={(e) => setEmbededModelName(e.target.value)}                                
                                type={"text"}
                            />
                            <span className="text-error-focus">{errorsList.filter((name) => name.path === "embededModelName")?.[0]?.errors?.[0]}</span>

                        </label>
                    </div>
                    {modelCreated === true ?   
            <div className="mt-4 ">
                        <label className="block">
                        <span>File:</span>
                        <input className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent" 
                            type="file" accept=".pdf,.doc,.docx" onChange={(e) => setFile(e.target.files[0])} />
                            <span className="text-error-focus">{errorsList.filter((name) => name.path === "file")?.[0]?.errors?.[0]}</span>

                        </label>

                    </div>
  
                :""}
                </form>
              
            </div>
            <div className="!text-right mx-16 px-4 py-4 dark:bg-navy-700">
              {modelCreated === true ?                   <button
                    onClick={() => handleUploadFile()}
                    className="btn min-w-[7rem] rounded-full bg-primary font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90"
                >
                    Upload
                </button> :
                <button
                    onClick={() => postNewModel()}
                    className="btn min-w-[7rem] rounded-full bg-primary font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90"
                >
                    Add
                </button>}
            </div>

                </>
    )
}

export default AddModel;