import HomePage1 from "../../../../../images/homepage1.webp";

const IntroductionContainer = () => {
    return (
        <>
            <div class="max-w-[85rem] mx-auto px-4 sm:px-6 lg:px-8">
                <div class="grid md:grid-cols-7 gap-4 md:gap-8 xl:gap-20 md:items-center">
                    <div class="md:col-span-4">
                        <h1 class="block text-2xl font-bold text-gray-800 sm:text-3xl lg:text-5xl lg:leading-tight dark:text-white">Start
                            Revolutionizing Higher Education with
                            <span class="bg-clip-text bg-gradient-to-tl from-jclogo-200 via-jclogo-400 to-jclogo-600 text-transparent ml-4">Generative AI</span></h1>
                        <p class="mt-3 text-lg text-gray-800 dark:text-neutral-400">
                            JOURNiCRAFT is a platform aimed at empowering higher education teachers to utilize generative AI for enhancing teaching and providing a personalized learning experience, viewing AI as an opportunity rather than a threat to education.
                        </p>

                        <div class="mt-7 grid gap-3 w-full sm:inline-flex">
                            <a className="py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-jclogo-400 text-white hover:bg-jclogo-600 focus:outline-none focus:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
                               href="/login">
                                Get started
                                <svg class="shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                     viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                     stroke-linecap="round" stroke-linejoin="round">
                                    <path d="m9 18 6-6-6-6"/>
                                </svg>
                            </a>
                            <a className="py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 focus:outline-none focus:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-800 dark:focus:bg-neutral-800"
                               href="mailto:journicraft.com@gmail.com">
                                Contact Us
                            </a>
                        </div>
                    </div>

                    <div class="md:col-span-3 relative ms-4">
                        <img className="w-full rounded-xl"
                             src={HomePage1}
                             alt="JOURNiCRAFT"/>

                    </div>
                </div>
            </div>

        </>
    )
}
export default IntroductionContainer;