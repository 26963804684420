import React, {useRef, useState} from 'react';
import moment from 'moment';
import userIcon from '../../../images/user-black.png';
import Popper from '@mui/material/Popper';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';
const MessageChat = (props) => {
    const message = props.message;
    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState("");  
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
      setOpen((prev) => !prev);
    };
    const messagesEndRef = useRef(null)
    return <div className='w-full flex flex-col items-start' id={"chat-feedback-menu-" + message?.id}>
            <div className="flex items-start justify-end space-x-2.5 sm:space-x-5">
                <div className="flex flex-col items-start space-y-3.5">
                    <div className="flex sm:ml-10">     
                    <div className="avatar mt-4">
                        <img
                            className="rounded-full"
                            // src={process.env.PUBLIC_URL + '/images/assistant/assistant_' + assistant.id + '.jpg'}
                            src={userIcon}
                            alt={"me"}
                        />
                    </div>                  
                    <div className="text-base flex flex-col  p-3 text-slate-700 dark:bg-navy-700 dark:text-navy-100">
                            <span className='font-bold whitespace-nowrap'>{message?.sender_name}</span>
                            <div className='flex'>To 
                             {message?.receivers_info.length > 3 ?
                            <svg onMouseLeave={() => setOpen(false)} onClick={(e) => handleClick(e)} className='mt-1 cursor-pointer' width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M17.9188 8.17969H11.6888H6.07877C5.11877 8.17969 4.63877 9.33969 5.31877 10.0197L10.4988 15.1997C11.3288 16.0297 12.6788 16.0297 13.5088 15.1997L15.4788 13.2297L18.6888 10.0197C19.3588 9.33969 18.8788 8.17969 17.9188 8.17969Z" className='fill-navy-200'></path> </g></svg>
                            :
                            message?.receivers_info.length > 0 && message?.receivers_info.map((receiver, index)=>{
                                return(
                                    <div className='ml-3' key={index}> {receiver?.receiver_name} {index != message?.receivers_info.length - 1 ? "," : ""}</div>
                                )
                            })       
                        }</div>
                            <div className='mt-5'  dangerouslySetInnerHTML={{__html: message?.body}}></div>
                    </div>                     
                    <p className="mt-3 ml-auto text-left text-xs text-slate-400 dark:text-navy-300">
                            {moment(message?.creation_date).format("MMMM DD YYYY")}
                    </p>
                    </div>
                </div>
                <div ref={messagesEndRef}/>
               
            </div>
            <hr className=" border w-4/5 border-b-slate-200 "/>

        <Popper
            className="!z-50 !w-60 "
            open={open}
            anchorEl={anchorEl}
            placement={"bottom"}
            transition>
                {({ TransitionProps }) => (
                <Fade className="px-3 !shadow-none rounded-md border border-slate-150 border-2 bg-slate-100 text-slate-800 dark:bg-navy-600 dark:text-navy-100"            
                {...TransitionProps} timeout={350}>
                    <Paper>
                        <ul>
                            {message?.receivers_info.length > 0 && message?.receivers_info.map((receiver, index)=>{
                                return(
                                    <li className='p-2' key={index}> {receiver?.receiver_name} </li>
                                )
                            })}                 
                       </ul>
                    </Paper>
                </Fade>
                )}
        </Popper>

    </div>
}

export default MessageChat;