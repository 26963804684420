import {useEffect} from "react";
import {Outlet} from "react-router-dom";

import Application from "../app";
import AppPreloader from "./preloader";
import {useLayoutContext} from "./layoutProvider";

const SiteLayout = (props) => {
    const {loader} = useLayoutContext();

    useEffect(() => {
        window.App = new Application();
        window.dispatchEvent(new CustomEvent("app:mounted"));
    }, []);

    return (
        <div>
            {loader?<AppPreloader />:<></>}
            <Outlet/>
        </div>
    );
}

export default SiteLayout;