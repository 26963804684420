import React, {useState} from "react";
const ButtonCard = (props) => {

    return (
        <div className={`card h-full col-span-1 items-center pb-5 text-center cursor-pointer ${props.selected?"shadow shadow-gray-700":""}`}>
            <div
                className={`h-24 w-full rounded-t-lg object-cover object-center ${props.bg_color}`}
            />
            <div class="avatar -mt-12 h-20 w-20">
                <img
                    className="rounded-full border-2 border-white dark:border-navy-700"
                    src={props?.image}
                    alt="avatar"
                />
            </div>
            <div class="mt-1.5 px-2">
                <a
                    href="#"
                    className="text-base font-medium text-slate-700 line-clamp-2 hover:text-primary focus:text-primary dark:text-navy-100 dark:hover:text-accent-light dark:focus:text-accent-light"
                >
                    {props?.title}
                </a>
                <p class="text-xs text-slate-400 dark:text-navy-300">
                    {props?.description}
                </p>
            </div>
        </div>
)
}

export default ButtonCard;