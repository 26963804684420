import {useLocation, useOutletContext} from "react-router-dom";
import {useEffect} from "react";

import ChatSidebarMenu from "./components/chatSidebarMenu";
import {useLayoutContext} from "../../layout/layoutProvider";
import {openCloseSidebarPanel} from "../../utils/helpers";

const ChatPage = () => {
    const location = useLocation()
    const {setLoader, setHeader, setSidebarSelectedMenuItem} = useLayoutContext();
    const user = useOutletContext().user;
    const course = useOutletContext().course;
    function updateMenu() {
        if (location.pathname.includes('chats')) {
            setSidebarSelectedMenuItem('chats');
        }
    }
    
  useEffect(() => {
    const handleClickOutside = (event) => {
      const sidebar = document.getElementById('page-sidebar-panel');
      const toggleButton = document.getElementById('sidebar-toggle-btn');
      const selectMenu = document.getElementById('select-type'); // Add reference to your select element
      if (sidebar && !sidebar.contains(event.target) && !toggleButton.contains(event.target)
      && !selectMenu) {
        openCloseSidebarPanel(false);
      }
    };

    window.addEventListener('click', handleClickOutside);

    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, [location]);

    useEffect(() => {
        setLoader(false);
        setHeader('', '');
        updateMenu();
    }, [location]);

    return <ChatSidebarMenu user={user} course={course}/>
}

export default ChatPage;