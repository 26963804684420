import React, {useState} from "react";
const ButtonCard = (props) => {

    return(
                <div className={`w-full rounded-lg flex ${props.color} text-center justify-center items-center dark:bg-${props.color}-700 cursor-pointer  h-24 space-x-1`}>
                    <p className="text-l font-semibold text-slate-700 dark:text-navy-300">
                        {props?.title}
                    </p>
                </div>
    )
}

export default ButtonCard;