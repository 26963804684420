import ChatMessage from "./chatMessage";
import React, {useEffect, useRef, useState} from "react";
import {useParams} from 'react-router';

import FetchClient from "../../../utils/fetchClient";
import {useLayoutContext} from "../../../layout/layoutProvider";
import {useLocation} from "react-router-dom";
import {extractQuestions} from "../../../utils/extractSuggestedQuestions";
import {useAuth} from "../../../utils/authProvider";

const Chat = (props) => {

    const location = useLocation()
    const {setHeader, setLoader} = useLayoutContext();
    const params = useParams();
    const [isWizard, setIsWizard] = useState(props?.isWizard ? true : false);
    const chatId = props?.isWizard ? props?.wizard?.id : props.isGeneral ? props.general?.id : params.chat_id;
    const screenHeight = props?.screenHeight ? props.screenHeight : "h-100vh";
    const topMargin = props?.topMargin ? props.topMargin : "mt-12";
    const sidePadding = props?.sidePadding ? props.sidePadding : "";
    const sendMessage = props.send_message;
    const [id, setId] = useState();

    const [messages, setMessages] = useState([]);
    const [inputs, setInputs] =
        useState({content: ""});
    const [waitForAnswer, setWaitForAnswer] = useState([0, false]);
    const [assistant, setAssistant] = useState({id: 0});
    const [liValues, setLiValues] = useState([]);
    const [waitForSuggestedQUestions, setWaitForSuggestedQuestions] = useState(false);
    const store = useAuth();
    const courseId = store?.stores?.course?.id;
    const [isFetching, setIsFetching] = useState(false);
    const [isReplyMessage, setIsReplyMessage] = useState(false);
    const [type, setType] = useState("");

    function updateChat() {
        setLiValues([]);
        FetchClient({
            method: 'get',
            url: `chats/` + chatId
        }).then(response => {
            setHeader(response.data.title,
                response.data.usage_prompt_tokens + response.data.usage_completion_tokens + ' tokens',
                chatId, response.data.exercise_id !== null ? response.data.exercise_id : -1);
            setId(response?.data?.id);
            setType(response?.data?.type);
            if (response?.data?.id) {
                getSuggestedQuestions(response.data.id);
            }
        })
    }
useEffect(()=>{
  if(messages.length > 0){
    setIsFetching(true)
  }
  else{
    setIsFetching(false)
  }
},[messages])
    const getAssistant = () => (

        FetchClient({
            method: 'get',
            url: 'chat/' + chatId + '/assistant'
        }).then(response => {
            setAssistant(response.data)
        })
    );

    function getMessages() {
        FetchClient({
            method: 'get',
            url: 'chat/' + chatId + '/messages',
            params: {
                chat_id: chatId
            }
        }).then(response => {
            setMessages(response.data);
            if (props?.isWizard) {
                setWaitForAnswer([response?.data[0]?.id, true]);
                getMessageReply(response?.data[0]?.id);

            }
            updateChat();
            setLoader(false);
        })
    }

    let requestForSuggestedQuestions = null;
    let timer = 0;
    const getSuggestedQuestions = (id) => {
        if (messages.length > 0 || isWizard) {
            FetchClient({
            method: 'get',
            url: `suggested_questions/${id}`,
            params: {
                course_id: courseId
            }
        }).then(response => {
            clearInterval(requestForSuggestedQuestions);
            clearInterval(timer);
            srollToEndOfMessages();
            setIsFetching(false)
            setWaitForSuggestedQuestions(false)
            setLiValues(extractQuestions(response?.data?.questions));
        }).catch((error) => {
            setIsFetching(true);
        });
    }
    }

    useEffect(() => {
        if (isFetching) {
            requestForSuggestedQuestions = setInterval(() => {
                getSuggestedQuestions(id);
            }, 2000);
            timer = setTimeout(() => {
                clearInterval(requestForSuggestedQuestions);
            }, 20000);
            return () => {
                clearInterval(requestForSuggestedQuestions);
                clearTimeout(timer);
            };
        }
    }, [isFetching, id]);
    useEffect(() => {
        updateChat();
        getAssistant();
        setIsReplyMessage(false);       
    }, [location]);

    function srollToEndOfMessages() {
        messagesEndRef.current.scrollIntoView({behavior: "smooth"});
    }

    const handleChange = (event) => {
        const {name, value} = event.target;
        setInputs(values => ({...values, [name]: value}))
    }
    const submitMessage = (event) => {
        event?.preventDefault();
        if ((inputs.content && inputs.content.trim()) || (messages.length === 1 && props?.isWizard)) {
            FetchClient({
                method: 'post',
                url: isWizard || type === "wizard" ? "chat/message" : `messages`,
                data: {
                    content: inputs.content,
                    role: 'user',
                    chat_id: props?.isWizard ? props?.wizard?.id : chatId,
                    type: 'message'
                }
            }).then(response => {
                setWaitForAnswer([response.data.id, true]);
                getMessageReply(response.data.id)
                setInputs({content: ""});
                setLiValues([]);
                setMessages((prev) => [...prev, response.data]);
                srollToEndOfMessages();
            });
        } else {

        }
    }
    useEffect(() => {
        setWaitForAnswer([0, false]);
        setLoader(true);
        getMessages();
        submitMessage();
    }, [location]);
    const messagesEndRef = useRef(null)
    useEffect(() => {
        srollToEndOfMessages();
    }, [messages]);

    let requestForReplyInterval = null;
    const getMessageReply = (messageId) => {

        if (messages.findIndex((obj => obj.id === -100)) === -1) {
            srollToEndOfMessages();
        }
        FetchClient({
            method: 'get',
            url: `reply_message/` + messageId,
            params: {
                reply_to_message_id: messageId,
                type: 'message'
            }
        }).then(response => {
            clearInterval(requestForReplyInterval);
            setIsReplyMessage(true);
            setWaitForAnswer([waitForAnswer[0], false])
            setMessages([...messages, response.data]);
            updateChat();
            srollToEndOfMessages();
        }).catch((error) => {
            if (error.response.status === 404) {
                // setWaitForAnswer([waitForAnswer[0], true]);
            } else if (error.request) {
            } else {
            }
        });
    }
    useEffect(() => {
        if (waitForAnswer[1]) {
            requestForReplyInterval =
                setInterval(() => getMessageReply(waitForAnswer[0]), 2e3);
            return () => clearInterval(requestForReplyInterval);
        }
    }, [waitForAnswer]);

    useEffect(() => {
        messagesEndRef.current?.scrollIntoView({behavior: 'smooth'});
    }, [liValues]);


    return (
        <main className={`main-content mt-0 flex flex-col ${screenHeight} ${sidePadding}`}>
            <div
                className="scrollbar-sm grow overflow-y-auto px-[calc(var(--margin-x)-.5rem)] py-2 transition-all duration-[.25s]"
            >
                <div className={`space-y-5 ${topMargin}`}>

                    {messages.map((m, index) => (
                        <ChatMessage lastItem={(index === messages.length - 1 && isReplyMessage) ? true : false}
                                     loading={waitForAnswer} key={m.id} role={m.role} type={m.type} chat_id={chatId}
                                     datetime={m.creation_date} content={m.content} id={m.id}
                                     asssitant_image_url={assistant.image_file_url}/>
                    ))}
                    <div className="w-full flex flex-wrap">
                        {liValues?.length > 0 && liValues.map((question, index) => (
                            <div key={index} onClick={() => handleChange({target: {name: "content", value: question}})}
                                 className="w-full p-2 sm:p-4">
                                <span className="text-success font-bold">{`Suggested Question ${index + 1}`}</span>
                                <div
                                    className="text-base cursor-pointer rounded-lg bg-lime-100 p-3 text-slate-700 shadow-sm dark:bg-navy-700 dark:text-navy-100 h-full">
                                    {question}
                                </div>
                            </div>
                        ))}
                    <div ref={messagesEndRef}/>
                    </div>

                </div>
            </div>

            <form onSubmit={submitMessage}
                  className={sendMessage === false ? "hidden" : "w-full relative flex h-12 shrink-0 items-center justify-between border-t border-slate-150 bg-white px-[calc(var(--margin-x)-.25rem)] transition-[padding,width] duration-[.25s] dark:border-navy-600 dark:bg-navy-800"}>
                <div className="-ml-1.5 flex flex-1 space-x-2">
                    <input
                        className="h-9 w-full form-input bg-transparent placeholder:text-slate-400/70"
                        placeholder="Write the message"
                        name="content"
                        value={inputs.content}
                        onChange={handleChange}
                        type={"text"}
                    />
                </div>

                <div className="-mr-1.5 flex">
                    {/*<button*/}
                    {/*    className="btn h-9 w-9 shrink-0 rounded-full p-0 text-slate-500 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:text-navy-200 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25"*/}
                    {/*>*/}
                    {/*    <svg*/}
                    {/*        xmlns="http://www.w3.org/2000/svg"*/}
                    {/*        className="h-5.5 w-5.5"*/}
                    {/*        fill="none"*/}
                    {/*        viewBox="0 0 24 24"*/}
                    {/*        stroke="currentColor"*/}
                    {/*        strokeWidth="1.5"*/}
                    {/*    >*/}
                    {/*        <path*/}
                    {/*            strokeLinecap="round"*/}
                    {/*            strokeLinejoin="round"*/}
                    {/*            d="M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"*/}
                    {/*        />*/}
                    {/*    </svg>*/}
                    {/*</button>*/}

                    <button
                        className="btn h-9 w-9 shrink-0 rounded-full p-0 text-primary hover:bg-primary/20 focus:bg-primary/20 active:bg-primary/25 dark:text-accent-light dark:hover:bg-accent-light/20 dark:focus:bg-accent-light/20 dark:active:bg-accent-light/25"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5.5 w-5.5"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth="1.5"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="m9.813 5.146 9.027 3.99c4.05 1.79 4.05 4.718 0 6.508l-9.027 3.99c-6.074 2.686-8.553.485-5.515-4.876l.917-1.613c.232-.41.232-1.09 0-1.5l-.917-1.623C1.26 4.66 3.749 2.46 9.813 5.146ZM6.094 12.389h7.341"
                            />
                        </svg>
                    </button>
                </div>
            </form>
        </main>
    );

}

export default Chat;