import {logoColors} from "./constants";

const logotypeWithIcon = (classNames = "h-20 w-20", viewBox = "0 0 2000 2000") => {
    let logoColor = logoColors.ligth;
    return <svg xmlns="http://www.w3.org/2000/svg"
                className={classNames + " mx-auto"}
                fill="none"
                viewBox={viewBox}>
        <g>
            <path fill={logoColor}
                  d="M382,182.6c7.4,0,12.8-2.1,16.2-6.4c3.5-4.3,5.2-9.8,5.2-16.5v-57.9h19.2v57.9c0,5.7-0.9,11-2.8,15.9   c-1.9,5-4.5,9.3-8,13s-7.7,6.6-12.6,8.7s-10.5,3.2-16.6,3.2c-2,0-3.5-0.1-4.4-0.2c-0.9-0.2-2.1-0.3-3.4-0.6v-17.9   c1.1,0.2,2.1,0.4,3.2,0.6C379.1,182.5,380.4,182.6,382,182.6z"/>
            <path fill={logoColor}
                  d="M439.4,150.7c0-6.8,1.1-13.3,3.3-19.4c2.2-6.1,5.4-11.4,9.5-15.8c4.2-4.5,9.3-8,15.4-10.6s13-3.9,20.6-3.9   s14.6,1.3,20.6,3.9c6.1,2.6,11.2,6.2,15.4,10.6s7.3,9.8,9.5,15.8c2.2,6.1,3.3,12.5,3.3,19.4s-1.1,13.4-3.3,19.4s-5.4,11.3-9.5,15.8   c-4.2,4.5-9.3,8-15.4,10.6c-6.1,2.6-13,3.9-20.6,3.9s-14.6-1.3-20.6-3.9c-6.1-2.6-11.2-6.2-15.4-10.6c-4.2-4.5-7.3-9.7-9.5-15.8   C440.5,164.1,439.4,157.7,439.4,150.7z M458.6,150.7c0,4.4,0.6,8.5,1.7,12.4s2.9,7.3,5.3,10.2c2.4,2.9,5.5,5.1,9.2,6.8   c3.7,1.7,8.2,2.5,13.4,2.5c5.2,0,9.7-0.8,13.4-2.5s6.8-3.9,9.2-6.8c2.4-2.9,4.2-6.3,5.3-10.2c1.1-3.9,1.7-8,1.7-12.4   s-0.6-8.5-1.7-12.4c-1.1-3.9-2.9-7.3-5.3-10.2c-2.4-2.9-5.5-5.1-9.2-6.8c-3.7-1.7-8.2-2.5-13.4-2.5c-5.2,0-9.7,0.8-13.4,2.5   c-3.7,1.7-6.8,3.9-9.2,6.8c-2.4,2.9-4.2,6.3-5.3,10.2C459.2,142.2,458.6,146.3,458.6,150.7z"/>
            <path fill={logoColor}
                  d="M594.6,182.6c7.4,0,12.8-2.1,16.3-6.4s5.3-9.8,5.3-16.5v-57.9h19.2v57.9c0,5.7-0.9,11-2.7,15.9   c-1.8,5-4.5,9.3-7.9,13c-3.5,3.7-7.7,6.6-12.8,8.7s-10.9,3.2-17.4,3.2s-12.3-1.1-17.4-3.2c-5.1-2.1-9.3-5-12.8-8.7s-6.1-8-7.9-13   c-1.8-5-2.7-10.3-2.7-15.9v-57.9H573v57.9c0,6.7,1.8,12.2,5.3,16.5C581.8,180.4,587.3,182.6,594.6,182.6z"/>
            <path fill={logoColor}
                  d="M738,199.7h-23.8l-39.2-49h20.5c5.1,0,9.4-1.2,12.7-3.5c3.4-2.3,5-6.3,5-12c0-5.3-1.5-9.3-4.4-11.8   s-6.3-3.8-10.2-3.8h-24v80h-19.2v-97.9h43.2c4.7,0,9.1,0.9,13.2,2.6c4.1,1.7,7.7,4,10.7,7c3,2.9,5.4,6.3,7.2,10.2   c1.8,3.9,2.6,8,2.6,12.4c0,3.5-0.6,6.9-1.8,10.1s-2.8,6.1-4.8,8.7s-4.5,4.9-7.3,6.9s-5.8,3.6-9,4.9L738,199.7z"/>
            <path fill={logoColor}
                  d="M815.1,167.4v-65.6h19.2v97.9h-19.2l-44.8-65.6v65.6h-19.2v-97.9h19.2L815.1,167.4z"/>
            <path fill={logoColor} fillOpacity=".6"
                  d="M852.1,98.2c0-3.4,1.1-6.3,3.3-8.6c2.2-2.3,5.2-3.5,9-3.5s6.8,1.2,9,3.6c2.1,2.4,3.2,5.3,3.2,8.6   c0,3.4-1.1,6.3-3.2,8.7c-2.1,2.4-5.1,3.6-9,3.6s-6.9-1.2-9-3.6C853.2,104.6,852.1,101.7,852.1,98.2z M874,199.7h-19.2v-80H874   V199.7z"/>
            <path fill={logoColor}
                  d="M910.1,150.7c0,4.5,0.5,8.6,1.5,12.5s2.7,7.2,5.1,10c2.4,2.8,5.6,5,9.6,6.6s8.9,2.4,14.8,2.4   c5,0,10-0.4,14.9-1.2c4.9-0.8,8.9-1.9,12-3.4v19.7c-3.9,1.2-8.3,2.1-13.1,2.6c-4.8,0.6-9.7,0.9-14.7,0.9c-7.9,0-14.9-1.3-21-3.9   s-11.3-6.2-15.4-10.6s-7.3-9.8-9.5-15.8c-2.2-6.1-3.3-12.6-3.3-19.5s1.1-13.4,3.3-19.5s5.4-11.4,9.5-15.8s9.3-8,15.4-10.6   c6.1-2.6,13.1-3.9,21-3.9c5,0,9.9,0.3,14.7,0.9s9.2,1.5,13.1,2.6v19.7c-3.1-1.5-7.1-2.6-12-3.4s-9.9-1.2-14.9-1.2   c-5.9,0-10.8,0.8-14.8,2.4s-7.2,3.8-9.6,6.6c-2.4,2.8-4.1,6.1-5.1,9.9C910.6,142.3,910.1,146.3,910.1,150.7z"/>
            <path fill={logoColor}
                  d="M1067.7,199.7h-23.8l-39.2-49h20.5c5.1,0,9.4-1.2,12.7-3.5c3.4-2.3,5-6.3,5-12c0-5.3-1.5-9.3-4.4-11.8   s-6.3-3.8-10.2-3.8h-24v80h-19.2v-97.9h43.2c4.7,0,9.1,0.9,13.2,2.6s7.7,4,10.7,7c3,2.9,5.4,6.3,7.2,10.2c1.8,3.9,2.6,8,2.6,12.4   c0,3.5-0.6,6.9-1.8,10.1c-1.2,3.2-2.8,6.1-4.8,8.7s-4.5,4.9-7.3,6.9s-5.8,3.6-9,4.9L1067.7,199.7z"/>
            <path fill={logoColor}
                  d="M1142.5,177.6h-41l-8.3,22.1h-20.8l40-97.9h19.2l40,97.9h-20.8L1142.5,177.6z M1108.2,159.7h27.5l-13.8-36.6   L1108.2,159.7z"/>
            <path fill={logoColor}
                  d="M1254.9,101.8v17.9h-51.2v22.1h41.6v17.9h-41.6v40h-19.2v-97.9H1254.9z"/>
            <path fill={logoColor} d="M1314,199.7h-19.2v-80h-30.4v-17.9h80v17.9H1314V199.7z"/>
        </g>
        <g>
            <path fill={logoColor} fillOpacity=".6"
                  d="M509.2,245.6c-3.7,0-6.8-1.3-9.3-3.8s-3.8-5.6-3.8-9.3s1.2-6.8,3.8-9.3s5.6-3.8,9.3-3.8s6.8,1.2,9.3,3.7v3.6   h-2.6c-1.7-2-3.9-3-6.5-3s-4.8,0.8-6.5,2.5s-2.5,3.8-2.5,6.2s0.8,4.6,2.5,6.2s3.8,2.5,6.5,2.5s4.8-1,6.5-3h2.6v3.6   C516.1,244.3,512.9,245.5,509.2,245.6L509.2,245.6z"/>
            <path fill={logoColor} fillOpacity=".6"
                  d="M537.3,245l-4.6-7.1c-1-1.5-2.4-2.2-4.2-2.2H526v9.4h-4.3v-25.2h9.5c2.1,0,3.8,0.7,5.2,2.1s2.1,3.1,2.1,5.1   c0,1.5-0.4,2.9-1.3,4.2s-2,2.1-3.3,2.5v0.1c1,0.2,2,1.1,3,2.6l3.8,5.7v2.8H537.3z M534,227.8c0-1.2-0.4-2.1-1.1-2.9   s-1.7-1.1-2.9-1.1h-3.9v8.1h3.9c1.2,0,2.1-0.4,2.9-1.1S534,228.9,534,227.8L534,227.8z"/>
            <path fill={logoColor} fillOpacity=".6"
                  d="M561.2,245l-3.1-7.7h-9.5l-3.1,7.7h-3.6v-2.8l9.2-22.4h4.5l9.2,22.4v2.8H561.2z M553.4,224.8L553.4,224.8   l-3.5,8.7h7L553.4,224.8z"/>
            <path fill={logoColor} fillOpacity=".6"
                  d="M571.6,223.7v7.5h10.7v3.9h-10.7v10h-4.3v-25.2h15.3v3.9L571.6,223.7L571.6,223.7z"/>
            <path fill={logoColor} fillOpacity=".6"
                  d="M595.3,223.7V245H591v-21.3h-7.5v-3.9h19.2v3.9H595.3z"/>
            <path fill={logoColor} fillOpacity=".6"
                  d="M618.9,245v-10.6l-7.6-11.8v-2.8h3.4L621,230h0.2l6.3-10.2h3.4v2.8l-7.6,11.8V245H618.9z"/>
            <path fill={logoColor} fillOpacity=".6"
                  d="M656.3,232.5c0,3.7-1.2,6.8-3.8,9.3s-5.6,3.8-9.3,3.8s-6.8-1.3-9.3-3.8s-3.8-5.6-3.8-9.3s1.2-6.8,3.8-9.3   s5.6-3.8,9.3-3.8s6.8,1.3,9.3,3.8S656.3,228.7,656.3,232.5L656.3,232.5z M652,232.5c0-2.5-0.8-4.6-2.5-6.3s-3.8-2.5-6.3-2.5   s-4.6,0.8-6.3,2.5s-2.5,3.8-2.5,6.2s0.8,4.6,2.5,6.2s3.8,2.5,6.3,2.5s4.6-0.8,6.3-2.5S652,234.9,652,232.5L652,232.5z"/>
            <path fill={logoColor} fillOpacity=".6"
                  d="M675.9,243c-1.8,1.7-4,2.5-6.8,2.5s-5-0.8-6.8-2.5s-2.7-3.8-2.7-6.4v-16.9h4.3v16.6c0,1.5,0.5,2.7,1.5,3.6   s2.2,1.4,3.6,1.4s2.6-0.5,3.6-1.4s1.5-2.1,1.5-3.6v-16.6h4.3v16.9C678.6,239.2,677.7,241.4,675.9,243z"/>
            <path fill={logoColor} fillOpacity=".6"
                  d="M698.7,245l-4.6-7.1c-1-1.5-2.4-2.2-4.2-2.2h-2.5v9.4h-4.3v-25.2h9.5c2.1,0,3.8,0.7,5.2,2.1s2.1,3.1,2.1,5.1   c0,1.5-0.4,2.9-1.3,4.2s-2,2.1-3.3,2.5v0.1c1,0.2,2,1.1,3,2.6l3.8,5.7v2.8H698.7z M695.4,227.8c0-1.2-0.4-2.1-1.1-2.9   s-1.7-1.1-2.9-1.1h-3.9v8.1h3.9c1.2,0,2.1-0.4,2.9-1.1S695.4,228.9,695.4,227.8L695.4,227.8z"/>
            <path fill={logoColor} fillOpacity=".6"
                  d="M721.2,236.5c0,2.6-0.9,4.8-2.8,6.4s-4.1,2.5-6.7,2.5v-4c1.4,0,2.6-0.5,3.6-1.5s1.5-2.2,1.5-3.6v-16.5h4.4   V236.5L721.2,236.5z"/>
            <path fill={logoColor} fillOpacity=".6"
                  d="M750.7,232.5c0,3.7-1.2,6.8-3.8,9.3s-5.6,3.8-9.3,3.8s-6.8-1.3-9.3-3.8s-3.8-5.6-3.8-9.3s1.2-6.8,3.8-9.3   s5.6-3.8,9.3-3.8s6.8,1.3,9.3,3.8S750.7,228.7,750.7,232.5L750.7,232.5z M746.4,232.5c0-2.5-0.8-4.6-2.5-6.3s-3.8-2.5-6.3-2.5   s-4.6,0.8-6.3,2.5s-2.5,3.8-2.5,6.2s0.8,4.6,2.5,6.2s3.8,2.5,6.3,2.5s4.6-0.8,6.3-2.5S746.4,234.9,746.4,232.5L746.4,232.5z"/>
            <path fill={logoColor} fillOpacity=".6"
                  d="M770.3,243c-1.8,1.7-4,2.5-6.8,2.5s-5-0.8-6.8-2.5s-2.7-3.8-2.7-6.4v-16.9h4.3v16.6c0,1.5,0.5,2.7,1.5,3.6   s2.2,1.4,3.6,1.4s2.6-0.5,3.6-1.4s1.5-2.1,1.5-3.6v-16.6h4.3v16.9C773,239.2,772.1,241.4,770.3,243z"/>
            <path fill={logoColor} fillOpacity=".6"
                  d="M793.1,245l-4.6-7.1c-1-1.5-2.4-2.2-4.2-2.2h-2.5v9.4h-4.3v-25.2h9.5c2.1,0,3.8,0.7,5.2,2.1s2.1,3.1,2.1,5.1   c0,1.5-0.4,2.9-1.3,4.2s-2,2.1-3.3,2.5v0.1c1,0.2,2,1.1,3,2.6l3.8,5.7v2.8H793.1z M789.8,227.8c0-1.2-0.4-2.1-1.1-2.9   s-1.7-1.1-2.9-1.1h-3.9v8.1h3.9c1.2,0,2.1-0.4,2.9-1.1S789.8,228.9,789.8,227.8L789.8,227.8z"/>
            <path fill={logoColor} fillOpacity=".6"
                  d="M815.7,245l-11.3-17.5h-0.1c0.1,3.1,0.1,5.2,0.1,6.3V245H800v-25.2h4.5l11.3,17.5h0.1   c-0.1-3.1-0.1-5.2-0.1-6.3v-11.2h4.3V245H815.7z"/>
            <path fill={logoColor} fillOpacity=".6"
                  d="M824.9,245v-25.2h15.6v3.9h-11.3v6.6h11v3.9h-11v6.9h11.3v3.9L824.9,245L824.9,245z"/>
            <path fill={logoColor} fillOpacity=".6"
                  d="M849.8,245v-10.6l-7.6-11.8v-2.8h3.4l6.3,10.2h0.2l6.3-10.2h3.4v2.8l-7.6,11.8V245H849.8z"/>
            <path fill={logoColor} fillOpacity=".6" d="M865.1,249.7H863V245h-2v-4.2h4.1V249.7z"/>
            <path fill={logoColor} fillOpacity=".6"
                  d="M882.1,223.7v7.5h10.7v3.9h-10.7v10h-4.3v-25.2H893v3.9L882.1,223.7L882.1,223.7z"/>
            <path fill={logoColor} fillOpacity=".6"
                  d="M921.2,232.5c0,3.7-1.2,6.8-3.8,9.3s-5.6,3.8-9.3,3.8s-6.8-1.3-9.3-3.8s-3.8-5.6-3.8-9.3s1.2-6.8,3.8-9.3   s5.6-3.8,9.3-3.8s6.8,1.3,9.3,3.8S921.2,228.7,921.2,232.5L921.2,232.5z M916.9,232.5c0-2.5-0.8-4.6-2.5-6.3s-3.8-2.5-6.3-2.5   s-4.6,0.8-6.3,2.5s-2.5,3.8-2.5,6.2s0.8,4.6,2.5,6.2s3.8,2.5,6.3,2.5s4.6-0.8,6.3-2.5S916.9,234.9,916.9,232.5L916.9,232.5z"/>
            <path fill={logoColor} fillOpacity=".6"
                  d="M940.4,245l-4.6-7.1c-1-1.5-2.4-2.2-4.2-2.2h-2.5v9.4h-4.3v-25.2h9.5c2.1,0,3.8,0.7,5.2,2.1s2.1,3.1,2.1,5.1   c0,1.5-0.4,2.9-1.3,4.2s-2,2.1-3.3,2.5v0.1c1,0.2,2,1.1,3,2.6l3.8,5.7v2.8H940.4z M937,227.8c0-1.2-0.4-2.1-1.1-2.9   s-1.7-1.1-2.9-1.1h-3.9v8.1h3.9c1.2,0,2.1-0.4,2.9-1.1S937.1,228.9,937,227.8L937,227.8z"/>
            <path fill={logoColor} fillOpacity=".6"
                  d="M957.9,245.6c-3.7,0-6.8-1.3-9.3-3.8s-3.8-5.6-3.8-9.3s1.2-6.8,3.8-9.3s5.6-3.8,9.3-3.8s6.8,1.2,9.3,3.7v3.6   h-2.6c-1.7-2-3.9-3-6.5-3s-4.8,0.8-6.5,2.5s-2.5,3.8-2.5,6.2s0.8,4.6,2.5,6.2s3.7,2.5,6,2.5s4.2-0.4,5.7-1.2v-4.1h-6.2v-3.9h10.5   v10.7c-1.5,0.9-2.9,1.6-4.4,2.1S960,245.5,957.9,245.6L957.9,245.6z"/>
            <path fill={logoColor} fillOpacity=".6"
                  d="M972,245v-25.2h15.6v3.9h-11.3v6.6h11v3.9h-11v6.9h11.3v3.9L972,245L972,245z"/>
            <path fill={logoColor} fillOpacity=".6"
                  d="M1005.2,245v-10.6l-7.6-11.8v-2.8h3.4l6.3,10.2h0.2l6.3-10.2h3.4v2.8l-7.6,11.8V245H1005.2z"/>
            <path fill={logoColor} fillOpacity=".6"
                  d="M1042.7,232.5c0,3.7-1.2,6.8-3.8,9.3s-5.6,3.8-9.3,3.8s-6.8-1.3-9.3-3.8s-3.8-5.6-3.8-9.3s1.2-6.8,3.8-9.3   s5.6-3.8,9.3-3.8s6.8,1.3,9.3,3.8S1042.7,228.7,1042.7,232.5L1042.7,232.5z M1038.3,232.5c0-2.5-0.8-4.6-2.5-6.3s-3.8-2.5-6.3-2.5   s-4.6,0.8-6.3,2.5c-1.7,1.7-2.5,3.8-2.5,6.2s0.8,4.6,2.5,6.2c1.7,1.6,3.8,2.5,6.3,2.5s4.6-0.8,6.3-2.5S1038.3,234.9,1038.3,232.5   L1038.3,232.5z"/>
            <path fill={logoColor} fillOpacity=".6"
                  d="M1062.3,243c-1.8,1.7-4,2.5-6.8,2.5s-5-0.8-6.8-2.5s-2.7-3.8-2.7-6.4v-16.9h4.3v16.6c0,1.5,0.5,2.7,1.5,3.6   s2.2,1.4,3.6,1.4s2.6-0.5,3.6-1.4s1.5-2.1,1.5-3.6v-16.6h4.3v16.9C1064.9,239.2,1064.1,241.4,1062.3,243z"/>
            <path fill={logoColor} fillOpacity=".6"
                  d="M1085.1,245l-4.6-7.1c-1-1.5-2.4-2.2-4.2-2.2h-2.5v9.4h-4.3v-25.2h9.5c2.1,0,3.8,0.7,5.2,2.1s2.1,3.1,2.1,5.1   c0,1.5-0.4,2.9-1.3,4.2s-2,2.1-3.3,2.5v0.1c1,0.2,2,1.1,3,2.6l3.8,5.7v2.8H1085.1z M1081.8,227.8c0-1.2-0.4-2.1-1.1-2.9   s-1.7-1.1-2.9-1.1h-3.9v8.1h3.9c1.2,0,2.1-0.4,2.9-1.1S1081.8,228.9,1081.8,227.8L1081.8,227.8z"/>
            <path fill={logoColor} fillOpacity=".6"
                  d="M1104.6,223.7v7.5h10.7v3.9h-10.7v10h-4.3v-25.2h15.3v3.9L1104.6,223.7L1104.6,223.7z"/>
            <path fill={logoColor} fillOpacity=".6"
                  d="M1134.8,243c-1.8,1.7-4,2.5-6.8,2.5s-5-0.8-6.8-2.5s-2.7-3.8-2.7-6.4v-16.9h4.3v16.6c0,1.5,0.5,2.7,1.5,3.6   s2.2,1.4,3.6,1.4s2.6-0.5,3.6-1.4s1.5-2.1,1.5-3.6v-16.6h4.3v16.9C1137.4,239.2,1136.5,241.4,1134.8,243z"/>
            <path fill={logoColor} fillOpacity=".6"
                  d="M1151.5,223.7V245h-4.3v-21.3h-7.5v-3.9h19.2v3.9H1151.5z"/>
            <path fill={logoColor} fillOpacity=".6"
                  d="M1177.6,243c-1.8,1.7-4,2.5-6.8,2.5s-5-0.8-6.8-2.5s-2.7-3.8-2.7-6.4v-16.9h4.3v16.6c0,1.5,0.5,2.7,1.5,3.6   s2.2,1.4,3.6,1.4s2.6-0.5,3.6-1.4s1.5-2.1,1.5-3.6v-16.6h4.3v16.9C1180.2,239.2,1179.4,241.4,1177.6,243z"/>
            <path fill={logoColor} fillOpacity=".6"
                  d="M1200.4,245l-4.6-7.1c-1-1.5-2.4-2.2-4.2-2.2h-2.5v9.4h-4.3v-25.2h9.5c2.1,0,3.8,0.7,5.2,2.1s2.1,3.1,2.1,5.1   c0,1.5-0.4,2.9-1.3,4.2s-2,2.1-3.3,2.5v0.1c1,0.2,2,1.1,3,2.6l3.8,5.7v2.8H1200.4z M1197,227.8c0-1.2-0.4-2.1-1.1-2.9   s-1.7-1.1-2.9-1.1h-3.9v8.1h3.9c1.2,0,2.1-0.4,2.9-1.1S1197.1,228.9,1197,227.8L1197,227.8z"/>
            <path fill={logoColor} fillOpacity=".6"
                  d="M1207.3,245v-25.2h15.6v3.9h-11.3v6.6h11v3.9h-11v6.9h11.3v3.9L1207.3,245L1207.3,245z"/>
        </g>
        <g>
            <path fill={logoColor}
                  d="M191.7,199.8c4.1-9.9,6.2-20.5,6.2-31.8v-5.1c-0.5-4.1-0.8-8.5-0.8-12.9s0.3-8.6,0.8-12.6V67.9   c-5.3,3.5-10.1,7.4-14.4,11.8c-8.8,9-15.6,19.5-20.2,31.7c-4.6,12.2-7,25.2-7,39c0,13.9,2.3,26.9,7,39c4.2,10.9,10,20.6,17.6,28.9   C185.3,212.7,189,206.5,191.7,199.8z"/>
            <path fill={logoColor}
                  d="M156.8,151.9v16.8c0,13.4-3.6,24.4-10.8,33c-7.2,8.5-18.5,12.8-33.8,12.8c-3.3,0-6.1-0.2-8.3-0.5   c-0.9-0.1-1.7-0.3-2.6-0.4c1.1,3.9,1.6,8.1,1.6,12.4c0,5.5-0.9,10.7-2.6,15.6c-0.8,2.3-1.9,4.5-3.1,6.5v0.7   c2.9,0.4,5.3,0.8,7.2,1.1c1.9,0.3,4.9,0.5,9.1,0.5c12.9,0,24.4-2.1,34.6-6.4s18.9-10.1,26.1-17.4c7.2-7.4,12.8-16,16.6-25.9   c3.9-9.9,5.8-20.5,5.8-31.8v-16.8h-39.8V151.9z"/>
            <g>
                <path fill={logoColor}
                      d="M22.2,225.8c0-5.5,0.9-10.6,2.6-15.5c1.7-4.9,4.3-9.1,7.6-12.7s7.4-6.4,12.3-8.5s10.4-3.1,16.5-3.1    s11.6,1,16.5,3.1s9,4.9,12.3,8.5s5.9,7.8,7.6,12.7c1.7,4.9,2.6,10,2.6,15.5s-0.9,10.7-2.6,15.6c-1.8,4.8-4.3,9-7.6,12.6    c-3.3,3.6-7.4,6.4-12.3,8.5s-10.4,3.1-16.5,3.1s-11.6-1-16.5-3.1s-9-4.9-12.3-8.5c-3.3-3.6-5.9-7.8-7.6-12.6    C23.1,236.5,22.2,231.3,22.2,225.8z M37.6,225.8c0,3.5,0.4,6.8,1.3,9.9c0.9,3.1,2.3,5.8,4.2,8.1c1.9,2.3,4.4,4.1,7.4,5.4    s6.6,2,10.8,2s7.8-0.7,10.8-2s5.4-3.1,7.4-5.4c1.9-2.3,3.3-5,4.2-8.1c0.9-3.1,1.3-6.4,1.3-9.9s-0.4-6.8-1.3-9.9s-2.3-5.8-4.2-8.1    c-1.9-2.3-4.4-4.1-7.4-5.4s-6.6-2-10.8-2c-4.2,0-7.8,0.7-10.8,2s-5.4,3.1-7.4,5.4c-1.9,2.3-3.3,5-4.2,8.1    C38,219,37.6,222.3,37.6,225.8z"/>
            </g>
            <path fill={logoColor} fillOpacity=".6"
                  d="M354.5,59.1c-1.8-4.9-4.3-9.1-7.6-12.7c-3.3-3.6-7.4-6.4-12.3-8.5s-10.4-3.1-16.5-3.1s-11.6,1-16.5,3.1   s-9,4.9-12.3,8.5s-5.9,7.8-7.6,12.7c-1.8,4.9-2.6,10-2.6,15.5s0.9,10.7,2.6,15.6c1.7,4.8,4.3,9,7.6,12.6c3.3,3.6,7.4,6.4,12.3,8.5   s10.4,3.1,16.5,3.1s11.6-1,16.5-3.1s9-4.9,12.3-8.5s5.9-7.8,7.6-12.6c1.7-4.8,2.6-10,2.6-15.6C357.1,69.1,356.2,64,354.5,59.1z    M340.4,84.5c-0.9,3.1-2.3,5.8-4.2,8.1c-1.9,2.3-4.4,4.1-7.4,5.4s-6.6,2-10.8,2c-4.2,0-7.8-0.7-10.8-2s-5.4-3.1-7.4-5.4   c-1.9-2.3-3.3-5-4.2-8.1c-0.9-3.1-1.3-6.4-1.3-9.9s0.4-6.8,1.3-9.9c0.9-3.1,2.3-5.8,4.2-8.1c1.9-2.3,4.4-4.1,7.4-5.4   c3-1.3,6.6-2,10.8-2c4.2,0,7.8,0.7,10.8,2s5.4,3.1,7.4,5.4c1.9,2.3,3.3,5,4.2,8.1c0.9,3.1,1.3,6.4,1.3,9.9   C341.7,78.1,341.3,81.4,340.4,84.5z"/>
            <g>
                <path fill={logoColor} fillOpacity=".6"
                      d="M199.9,134.5c0.5-3.4,1.2-6.7,2.1-9.9c2.1-7.6,5.6-14.2,10.5-19.8c4.9-5.6,11.5-10.1,19.7-13.3    c8.2-3.2,18.4-4.8,30.4-4.8c5.5,0,11,0.2,16.5,0.7c-1.7-4.3-2.6-9-2.6-13.9c0-8.6,2.8-16.5,7.6-22.9c-7.7-0.7-15.5-1.1-23.4-1.1    c-16.2,0-30.6,2.6-43.3,7.8c-6.4,2.6-12.2,5.7-17.5,9.3L199.9,134.5L199.9,134.5z"/>
                <path fill={logoColor} fillOpacity=".6"
                      d="M293.3,209.7c-10.1,1.6-20.3,2.4-30.6,2.4c-12.1,0-22.2-1.6-30.4-4.8c-8.2-3.2-14.8-7.6-19.7-13.3    c-4.9-5.6-8.4-12.3-10.5-20c-0.9-3.2-1.6-6.6-2.1-10v5.4c0,11.3-2,21.9-5.9,31.8c-2.5,6.3-5.7,12.1-9.7,17.4    c0.5,0.5,0.9,1,1.4,1.5c8.6,9,19.1,16.1,31.7,21.3c12.6,5.2,27,7.8,43.3,7.8c10.3,0,20.4-0.6,30.3-1.8s18.9-2.9,27-5.3v-39.4    C311.6,205.8,303.3,208.1,293.3,209.7z"/>
            </g>
        </g>
    </svg>
}

const logotypeWithIconMedium = (classNames = "h-20 w-20", viewBox = "0 0 2000 2000") => {
    let logoColor = logoColors.medium;
    return <svg xmlns="http://www.w3.org/2000/svg"
                className={classNames + " mx-auto"}
                fill="none"
                viewBox={viewBox}>
        <g>
            <path fill={logoColor}
                  d="M382,182.6c7.4,0,12.8-2.1,16.2-6.4c3.5-4.3,5.2-9.8,5.2-16.5v-57.9h19.2v57.9c0,5.7-0.9,11-2.8,15.9   c-1.9,5-4.5,9.3-8,13s-7.7,6.6-12.6,8.7s-10.5,3.2-16.6,3.2c-2,0-3.5-0.1-4.4-0.2c-0.9-0.2-2.1-0.3-3.4-0.6v-17.9   c1.1,0.2,2.1,0.4,3.2,0.6C379.1,182.5,380.4,182.6,382,182.6z"/>
            <path fill={logoColor}
                  d="M439.4,150.7c0-6.8,1.1-13.3,3.3-19.4c2.2-6.1,5.4-11.4,9.5-15.8c4.2-4.5,9.3-8,15.4-10.6s13-3.9,20.6-3.9   s14.6,1.3,20.6,3.9c6.1,2.6,11.2,6.2,15.4,10.6s7.3,9.8,9.5,15.8c2.2,6.1,3.3,12.5,3.3,19.4s-1.1,13.4-3.3,19.4s-5.4,11.3-9.5,15.8   c-4.2,4.5-9.3,8-15.4,10.6c-6.1,2.6-13,3.9-20.6,3.9s-14.6-1.3-20.6-3.9c-6.1-2.6-11.2-6.2-15.4-10.6c-4.2-4.5-7.3-9.7-9.5-15.8   C440.5,164.1,439.4,157.7,439.4,150.7z M458.6,150.7c0,4.4,0.6,8.5,1.7,12.4s2.9,7.3,5.3,10.2c2.4,2.9,5.5,5.1,9.2,6.8   c3.7,1.7,8.2,2.5,13.4,2.5c5.2,0,9.7-0.8,13.4-2.5s6.8-3.9,9.2-6.8c2.4-2.9,4.2-6.3,5.3-10.2c1.1-3.9,1.7-8,1.7-12.4   s-0.6-8.5-1.7-12.4c-1.1-3.9-2.9-7.3-5.3-10.2c-2.4-2.9-5.5-5.1-9.2-6.8c-3.7-1.7-8.2-2.5-13.4-2.5c-5.2,0-9.7,0.8-13.4,2.5   c-3.7,1.7-6.8,3.9-9.2,6.8c-2.4,2.9-4.2,6.3-5.3,10.2C459.2,142.2,458.6,146.3,458.6,150.7z"/>
            <path fill={logoColor}
                  d="M594.6,182.6c7.4,0,12.8-2.1,16.3-6.4s5.3-9.8,5.3-16.5v-57.9h19.2v57.9c0,5.7-0.9,11-2.7,15.9   c-1.8,5-4.5,9.3-7.9,13c-3.5,3.7-7.7,6.6-12.8,8.7s-10.9,3.2-17.4,3.2s-12.3-1.1-17.4-3.2c-5.1-2.1-9.3-5-12.8-8.7s-6.1-8-7.9-13   c-1.8-5-2.7-10.3-2.7-15.9v-57.9H573v57.9c0,6.7,1.8,12.2,5.3,16.5C581.8,180.4,587.3,182.6,594.6,182.6z"/>
            <path fill={logoColor}
                  d="M738,199.7h-23.8l-39.2-49h20.5c5.1,0,9.4-1.2,12.7-3.5c3.4-2.3,5-6.3,5-12c0-5.3-1.5-9.3-4.4-11.8   s-6.3-3.8-10.2-3.8h-24v80h-19.2v-97.9h43.2c4.7,0,9.1,0.9,13.2,2.6c4.1,1.7,7.7,4,10.7,7c3,2.9,5.4,6.3,7.2,10.2   c1.8,3.9,2.6,8,2.6,12.4c0,3.5-0.6,6.9-1.8,10.1s-2.8,6.1-4.8,8.7s-4.5,4.9-7.3,6.9s-5.8,3.6-9,4.9L738,199.7z"/>
            <path fill={logoColor}
                  d="M815.1,167.4v-65.6h19.2v97.9h-19.2l-44.8-65.6v65.6h-19.2v-97.9h19.2L815.1,167.4z"/>
            <path fill={logoColor} fillOpacity=".6"
                  d="M852.1,98.2c0-3.4,1.1-6.3,3.3-8.6c2.2-2.3,5.2-3.5,9-3.5s6.8,1.2,9,3.6c2.1,2.4,3.2,5.3,3.2,8.6   c0,3.4-1.1,6.3-3.2,8.7c-2.1,2.4-5.1,3.6-9,3.6s-6.9-1.2-9-3.6C853.2,104.6,852.1,101.7,852.1,98.2z M874,199.7h-19.2v-80H874   V199.7z"/>
            <path fill={logoColor}
                  d="M910.1,150.7c0,4.5,0.5,8.6,1.5,12.5s2.7,7.2,5.1,10c2.4,2.8,5.6,5,9.6,6.6s8.9,2.4,14.8,2.4   c5,0,10-0.4,14.9-1.2c4.9-0.8,8.9-1.9,12-3.4v19.7c-3.9,1.2-8.3,2.1-13.1,2.6c-4.8,0.6-9.7,0.9-14.7,0.9c-7.9,0-14.9-1.3-21-3.9   s-11.3-6.2-15.4-10.6s-7.3-9.8-9.5-15.8c-2.2-6.1-3.3-12.6-3.3-19.5s1.1-13.4,3.3-19.5s5.4-11.4,9.5-15.8s9.3-8,15.4-10.6   c6.1-2.6,13.1-3.9,21-3.9c5,0,9.9,0.3,14.7,0.9s9.2,1.5,13.1,2.6v19.7c-3.1-1.5-7.1-2.6-12-3.4s-9.9-1.2-14.9-1.2   c-5.9,0-10.8,0.8-14.8,2.4s-7.2,3.8-9.6,6.6c-2.4,2.8-4.1,6.1-5.1,9.9C910.6,142.3,910.1,146.3,910.1,150.7z"/>
            <path fill={logoColor}
                  d="M1067.7,199.7h-23.8l-39.2-49h20.5c5.1,0,9.4-1.2,12.7-3.5c3.4-2.3,5-6.3,5-12c0-5.3-1.5-9.3-4.4-11.8   s-6.3-3.8-10.2-3.8h-24v80h-19.2v-97.9h43.2c4.7,0,9.1,0.9,13.2,2.6s7.7,4,10.7,7c3,2.9,5.4,6.3,7.2,10.2c1.8,3.9,2.6,8,2.6,12.4   c0,3.5-0.6,6.9-1.8,10.1c-1.2,3.2-2.8,6.1-4.8,8.7s-4.5,4.9-7.3,6.9s-5.8,3.6-9,4.9L1067.7,199.7z"/>
            <path fill={logoColor}
                  d="M1142.5,177.6h-41l-8.3,22.1h-20.8l40-97.9h19.2l40,97.9h-20.8L1142.5,177.6z M1108.2,159.7h27.5l-13.8-36.6   L1108.2,159.7z"/>
            <path fill={logoColor}
                  d="M1254.9,101.8v17.9h-51.2v22.1h41.6v17.9h-41.6v40h-19.2v-97.9H1254.9z"/>
            <path fill={logoColor} d="M1314,199.7h-19.2v-80h-30.4v-17.9h80v17.9H1314V199.7z"/>
        </g>
        <g>
            <path fill={logoColor} fillOpacity=".6"
                  d="M509.2,245.6c-3.7,0-6.8-1.3-9.3-3.8s-3.8-5.6-3.8-9.3s1.2-6.8,3.8-9.3s5.6-3.8,9.3-3.8s6.8,1.2,9.3,3.7v3.6   h-2.6c-1.7-2-3.9-3-6.5-3s-4.8,0.8-6.5,2.5s-2.5,3.8-2.5,6.2s0.8,4.6,2.5,6.2s3.8,2.5,6.5,2.5s4.8-1,6.5-3h2.6v3.6   C516.1,244.3,512.9,245.5,509.2,245.6L509.2,245.6z"/>
            <path fill={logoColor} fillOpacity=".6"
                  d="M537.3,245l-4.6-7.1c-1-1.5-2.4-2.2-4.2-2.2H526v9.4h-4.3v-25.2h9.5c2.1,0,3.8,0.7,5.2,2.1s2.1,3.1,2.1,5.1   c0,1.5-0.4,2.9-1.3,4.2s-2,2.1-3.3,2.5v0.1c1,0.2,2,1.1,3,2.6l3.8,5.7v2.8H537.3z M534,227.8c0-1.2-0.4-2.1-1.1-2.9   s-1.7-1.1-2.9-1.1h-3.9v8.1h3.9c1.2,0,2.1-0.4,2.9-1.1S534,228.9,534,227.8L534,227.8z"/>
            <path fill={logoColor} fillOpacity=".6"
                  d="M561.2,245l-3.1-7.7h-9.5l-3.1,7.7h-3.6v-2.8l9.2-22.4h4.5l9.2,22.4v2.8H561.2z M553.4,224.8L553.4,224.8   l-3.5,8.7h7L553.4,224.8z"/>
            <path fill={logoColor} fillOpacity=".6"
                  d="M571.6,223.7v7.5h10.7v3.9h-10.7v10h-4.3v-25.2h15.3v3.9L571.6,223.7L571.6,223.7z"/>
            <path fill={logoColor} fillOpacity=".6"
                  d="M595.3,223.7V245H591v-21.3h-7.5v-3.9h19.2v3.9H595.3z"/>
            <path fill={logoColor} fillOpacity=".6"
                  d="M618.9,245v-10.6l-7.6-11.8v-2.8h3.4L621,230h0.2l6.3-10.2h3.4v2.8l-7.6,11.8V245H618.9z"/>
            <path fill={logoColor} fillOpacity=".6"
                  d="M656.3,232.5c0,3.7-1.2,6.8-3.8,9.3s-5.6,3.8-9.3,3.8s-6.8-1.3-9.3-3.8s-3.8-5.6-3.8-9.3s1.2-6.8,3.8-9.3   s5.6-3.8,9.3-3.8s6.8,1.3,9.3,3.8S656.3,228.7,656.3,232.5L656.3,232.5z M652,232.5c0-2.5-0.8-4.6-2.5-6.3s-3.8-2.5-6.3-2.5   s-4.6,0.8-6.3,2.5s-2.5,3.8-2.5,6.2s0.8,4.6,2.5,6.2s3.8,2.5,6.3,2.5s4.6-0.8,6.3-2.5S652,234.9,652,232.5L652,232.5z"/>
            <path fill={logoColor} fillOpacity=".6"
                  d="M675.9,243c-1.8,1.7-4,2.5-6.8,2.5s-5-0.8-6.8-2.5s-2.7-3.8-2.7-6.4v-16.9h4.3v16.6c0,1.5,0.5,2.7,1.5,3.6   s2.2,1.4,3.6,1.4s2.6-0.5,3.6-1.4s1.5-2.1,1.5-3.6v-16.6h4.3v16.9C678.6,239.2,677.7,241.4,675.9,243z"/>
            <path fill={logoColor} fillOpacity=".6"
                  d="M698.7,245l-4.6-7.1c-1-1.5-2.4-2.2-4.2-2.2h-2.5v9.4h-4.3v-25.2h9.5c2.1,0,3.8,0.7,5.2,2.1s2.1,3.1,2.1,5.1   c0,1.5-0.4,2.9-1.3,4.2s-2,2.1-3.3,2.5v0.1c1,0.2,2,1.1,3,2.6l3.8,5.7v2.8H698.7z M695.4,227.8c0-1.2-0.4-2.1-1.1-2.9   s-1.7-1.1-2.9-1.1h-3.9v8.1h3.9c1.2,0,2.1-0.4,2.9-1.1S695.4,228.9,695.4,227.8L695.4,227.8z"/>
            <path fill={logoColor} fillOpacity=".6"
                  d="M721.2,236.5c0,2.6-0.9,4.8-2.8,6.4s-4.1,2.5-6.7,2.5v-4c1.4,0,2.6-0.5,3.6-1.5s1.5-2.2,1.5-3.6v-16.5h4.4   V236.5L721.2,236.5z"/>
            <path fill={logoColor} fillOpacity=".6"
                  d="M750.7,232.5c0,3.7-1.2,6.8-3.8,9.3s-5.6,3.8-9.3,3.8s-6.8-1.3-9.3-3.8s-3.8-5.6-3.8-9.3s1.2-6.8,3.8-9.3   s5.6-3.8,9.3-3.8s6.8,1.3,9.3,3.8S750.7,228.7,750.7,232.5L750.7,232.5z M746.4,232.5c0-2.5-0.8-4.6-2.5-6.3s-3.8-2.5-6.3-2.5   s-4.6,0.8-6.3,2.5s-2.5,3.8-2.5,6.2s0.8,4.6,2.5,6.2s3.8,2.5,6.3,2.5s4.6-0.8,6.3-2.5S746.4,234.9,746.4,232.5L746.4,232.5z"/>
            <path fill={logoColor} fillOpacity=".6"
                  d="M770.3,243c-1.8,1.7-4,2.5-6.8,2.5s-5-0.8-6.8-2.5s-2.7-3.8-2.7-6.4v-16.9h4.3v16.6c0,1.5,0.5,2.7,1.5,3.6   s2.2,1.4,3.6,1.4s2.6-0.5,3.6-1.4s1.5-2.1,1.5-3.6v-16.6h4.3v16.9C773,239.2,772.1,241.4,770.3,243z"/>
            <path fill={logoColor} fillOpacity=".6"
                  d="M793.1,245l-4.6-7.1c-1-1.5-2.4-2.2-4.2-2.2h-2.5v9.4h-4.3v-25.2h9.5c2.1,0,3.8,0.7,5.2,2.1s2.1,3.1,2.1,5.1   c0,1.5-0.4,2.9-1.3,4.2s-2,2.1-3.3,2.5v0.1c1,0.2,2,1.1,3,2.6l3.8,5.7v2.8H793.1z M789.8,227.8c0-1.2-0.4-2.1-1.1-2.9   s-1.7-1.1-2.9-1.1h-3.9v8.1h3.9c1.2,0,2.1-0.4,2.9-1.1S789.8,228.9,789.8,227.8L789.8,227.8z"/>
            <path fill={logoColor} fillOpacity=".6"
                  d="M815.7,245l-11.3-17.5h-0.1c0.1,3.1,0.1,5.2,0.1,6.3V245H800v-25.2h4.5l11.3,17.5h0.1   c-0.1-3.1-0.1-5.2-0.1-6.3v-11.2h4.3V245H815.7z"/>
            <path fill={logoColor} fillOpacity=".6"
                  d="M824.9,245v-25.2h15.6v3.9h-11.3v6.6h11v3.9h-11v6.9h11.3v3.9L824.9,245L824.9,245z"/>
            <path fill={logoColor} fillOpacity=".6"
                  d="M849.8,245v-10.6l-7.6-11.8v-2.8h3.4l6.3,10.2h0.2l6.3-10.2h3.4v2.8l-7.6,11.8V245H849.8z"/>
            <path fill={logoColor} fillOpacity=".6" d="M865.1,249.7H863V245h-2v-4.2h4.1V249.7z"/>
            <path fill={logoColor} fillOpacity=".6"
                  d="M882.1,223.7v7.5h10.7v3.9h-10.7v10h-4.3v-25.2H893v3.9L882.1,223.7L882.1,223.7z"/>
            <path fill={logoColor} fillOpacity=".6"
                  d="M921.2,232.5c0,3.7-1.2,6.8-3.8,9.3s-5.6,3.8-9.3,3.8s-6.8-1.3-9.3-3.8s-3.8-5.6-3.8-9.3s1.2-6.8,3.8-9.3   s5.6-3.8,9.3-3.8s6.8,1.3,9.3,3.8S921.2,228.7,921.2,232.5L921.2,232.5z M916.9,232.5c0-2.5-0.8-4.6-2.5-6.3s-3.8-2.5-6.3-2.5   s-4.6,0.8-6.3,2.5s-2.5,3.8-2.5,6.2s0.8,4.6,2.5,6.2s3.8,2.5,6.3,2.5s4.6-0.8,6.3-2.5S916.9,234.9,916.9,232.5L916.9,232.5z"/>
            <path fill={logoColor} fillOpacity=".6"
                  d="M940.4,245l-4.6-7.1c-1-1.5-2.4-2.2-4.2-2.2h-2.5v9.4h-4.3v-25.2h9.5c2.1,0,3.8,0.7,5.2,2.1s2.1,3.1,2.1,5.1   c0,1.5-0.4,2.9-1.3,4.2s-2,2.1-3.3,2.5v0.1c1,0.2,2,1.1,3,2.6l3.8,5.7v2.8H940.4z M937,227.8c0-1.2-0.4-2.1-1.1-2.9   s-1.7-1.1-2.9-1.1h-3.9v8.1h3.9c1.2,0,2.1-0.4,2.9-1.1S937.1,228.9,937,227.8L937,227.8z"/>
            <path fill={logoColor} fillOpacity=".6"
                  d="M957.9,245.6c-3.7,0-6.8-1.3-9.3-3.8s-3.8-5.6-3.8-9.3s1.2-6.8,3.8-9.3s5.6-3.8,9.3-3.8s6.8,1.2,9.3,3.7v3.6   h-2.6c-1.7-2-3.9-3-6.5-3s-4.8,0.8-6.5,2.5s-2.5,3.8-2.5,6.2s0.8,4.6,2.5,6.2s3.7,2.5,6,2.5s4.2-0.4,5.7-1.2v-4.1h-6.2v-3.9h10.5   v10.7c-1.5,0.9-2.9,1.6-4.4,2.1S960,245.5,957.9,245.6L957.9,245.6z"/>
            <path fill={logoColor} fillOpacity=".6"
                  d="M972,245v-25.2h15.6v3.9h-11.3v6.6h11v3.9h-11v6.9h11.3v3.9L972,245L972,245z"/>
            <path fill={logoColor} fillOpacity=".6"
                  d="M1005.2,245v-10.6l-7.6-11.8v-2.8h3.4l6.3,10.2h0.2l6.3-10.2h3.4v2.8l-7.6,11.8V245H1005.2z"/>
            <path fill={logoColor} fillOpacity=".6"
                  d="M1042.7,232.5c0,3.7-1.2,6.8-3.8,9.3s-5.6,3.8-9.3,3.8s-6.8-1.3-9.3-3.8s-3.8-5.6-3.8-9.3s1.2-6.8,3.8-9.3   s5.6-3.8,9.3-3.8s6.8,1.3,9.3,3.8S1042.7,228.7,1042.7,232.5L1042.7,232.5z M1038.3,232.5c0-2.5-0.8-4.6-2.5-6.3s-3.8-2.5-6.3-2.5   s-4.6,0.8-6.3,2.5c-1.7,1.7-2.5,3.8-2.5,6.2s0.8,4.6,2.5,6.2c1.7,1.6,3.8,2.5,6.3,2.5s4.6-0.8,6.3-2.5S1038.3,234.9,1038.3,232.5   L1038.3,232.5z"/>
            <path fill={logoColor} fillOpacity=".6"
                  d="M1062.3,243c-1.8,1.7-4,2.5-6.8,2.5s-5-0.8-6.8-2.5s-2.7-3.8-2.7-6.4v-16.9h4.3v16.6c0,1.5,0.5,2.7,1.5,3.6   s2.2,1.4,3.6,1.4s2.6-0.5,3.6-1.4s1.5-2.1,1.5-3.6v-16.6h4.3v16.9C1064.9,239.2,1064.1,241.4,1062.3,243z"/>
            <path fill={logoColor} fillOpacity=".6"
                  d="M1085.1,245l-4.6-7.1c-1-1.5-2.4-2.2-4.2-2.2h-2.5v9.4h-4.3v-25.2h9.5c2.1,0,3.8,0.7,5.2,2.1s2.1,3.1,2.1,5.1   c0,1.5-0.4,2.9-1.3,4.2s-2,2.1-3.3,2.5v0.1c1,0.2,2,1.1,3,2.6l3.8,5.7v2.8H1085.1z M1081.8,227.8c0-1.2-0.4-2.1-1.1-2.9   s-1.7-1.1-2.9-1.1h-3.9v8.1h3.9c1.2,0,2.1-0.4,2.9-1.1S1081.8,228.9,1081.8,227.8L1081.8,227.8z"/>
            <path fill={logoColor} fillOpacity=".6"
                  d="M1104.6,223.7v7.5h10.7v3.9h-10.7v10h-4.3v-25.2h15.3v3.9L1104.6,223.7L1104.6,223.7z"/>
            <path fill={logoColor} fillOpacity=".6"
                  d="M1134.8,243c-1.8,1.7-4,2.5-6.8,2.5s-5-0.8-6.8-2.5s-2.7-3.8-2.7-6.4v-16.9h4.3v16.6c0,1.5,0.5,2.7,1.5,3.6   s2.2,1.4,3.6,1.4s2.6-0.5,3.6-1.4s1.5-2.1,1.5-3.6v-16.6h4.3v16.9C1137.4,239.2,1136.5,241.4,1134.8,243z"/>
            <path fill={logoColor} fillOpacity=".6"
                  d="M1151.5,223.7V245h-4.3v-21.3h-7.5v-3.9h19.2v3.9H1151.5z"/>
            <path fill={logoColor} fillOpacity=".6"
                  d="M1177.6,243c-1.8,1.7-4,2.5-6.8,2.5s-5-0.8-6.8-2.5s-2.7-3.8-2.7-6.4v-16.9h4.3v16.6c0,1.5,0.5,2.7,1.5,3.6   s2.2,1.4,3.6,1.4s2.6-0.5,3.6-1.4s1.5-2.1,1.5-3.6v-16.6h4.3v16.9C1180.2,239.2,1179.4,241.4,1177.6,243z"/>
            <path fill={logoColor} fillOpacity=".6"
                  d="M1200.4,245l-4.6-7.1c-1-1.5-2.4-2.2-4.2-2.2h-2.5v9.4h-4.3v-25.2h9.5c2.1,0,3.8,0.7,5.2,2.1s2.1,3.1,2.1,5.1   c0,1.5-0.4,2.9-1.3,4.2s-2,2.1-3.3,2.5v0.1c1,0.2,2,1.1,3,2.6l3.8,5.7v2.8H1200.4z M1197,227.8c0-1.2-0.4-2.1-1.1-2.9   s-1.7-1.1-2.9-1.1h-3.9v8.1h3.9c1.2,0,2.1-0.4,2.9-1.1S1197.1,228.9,1197,227.8L1197,227.8z"/>
            <path fill={logoColor} fillOpacity=".6"
                  d="M1207.3,245v-25.2h15.6v3.9h-11.3v6.6h11v3.9h-11v6.9h11.3v3.9L1207.3,245L1207.3,245z"/>
        </g>
        <g>
            <path fill={logoColor}
                  d="M191.7,199.8c4.1-9.9,6.2-20.5,6.2-31.8v-5.1c-0.5-4.1-0.8-8.5-0.8-12.9s0.3-8.6,0.8-12.6V67.9   c-5.3,3.5-10.1,7.4-14.4,11.8c-8.8,9-15.6,19.5-20.2,31.7c-4.6,12.2-7,25.2-7,39c0,13.9,2.3,26.9,7,39c4.2,10.9,10,20.6,17.6,28.9   C185.3,212.7,189,206.5,191.7,199.8z"/>
            <path fill={logoColor}
                  d="M156.8,151.9v16.8c0,13.4-3.6,24.4-10.8,33c-7.2,8.5-18.5,12.8-33.8,12.8c-3.3,0-6.1-0.2-8.3-0.5   c-0.9-0.1-1.7-0.3-2.6-0.4c1.1,3.9,1.6,8.1,1.6,12.4c0,5.5-0.9,10.7-2.6,15.6c-0.8,2.3-1.9,4.5-3.1,6.5v0.7   c2.9,0.4,5.3,0.8,7.2,1.1c1.9,0.3,4.9,0.5,9.1,0.5c12.9,0,24.4-2.1,34.6-6.4s18.9-10.1,26.1-17.4c7.2-7.4,12.8-16,16.6-25.9   c3.9-9.9,5.8-20.5,5.8-31.8v-16.8h-39.8V151.9z"/>
            <g>
                <path fill={logoColor}
                      d="M22.2,225.8c0-5.5,0.9-10.6,2.6-15.5c1.7-4.9,4.3-9.1,7.6-12.7s7.4-6.4,12.3-8.5s10.4-3.1,16.5-3.1    s11.6,1,16.5,3.1s9,4.9,12.3,8.5s5.9,7.8,7.6,12.7c1.7,4.9,2.6,10,2.6,15.5s-0.9,10.7-2.6,15.6c-1.8,4.8-4.3,9-7.6,12.6    c-3.3,3.6-7.4,6.4-12.3,8.5s-10.4,3.1-16.5,3.1s-11.6-1-16.5-3.1s-9-4.9-12.3-8.5c-3.3-3.6-5.9-7.8-7.6-12.6    C23.1,236.5,22.2,231.3,22.2,225.8z M37.6,225.8c0,3.5,0.4,6.8,1.3,9.9c0.9,3.1,2.3,5.8,4.2,8.1c1.9,2.3,4.4,4.1,7.4,5.4    s6.6,2,10.8,2s7.8-0.7,10.8-2s5.4-3.1,7.4-5.4c1.9-2.3,3.3-5,4.2-8.1c0.9-3.1,1.3-6.4,1.3-9.9s-0.4-6.8-1.3-9.9s-2.3-5.8-4.2-8.1    c-1.9-2.3-4.4-4.1-7.4-5.4s-6.6-2-10.8-2c-4.2,0-7.8,0.7-10.8,2s-5.4,3.1-7.4,5.4c-1.9,2.3-3.3,5-4.2,8.1    C38,219,37.6,222.3,37.6,225.8z"/>
            </g>
            <path fill={logoColor} fillOpacity=".6"
                  d="M354.5,59.1c-1.8-4.9-4.3-9.1-7.6-12.7c-3.3-3.6-7.4-6.4-12.3-8.5s-10.4-3.1-16.5-3.1s-11.6,1-16.5,3.1   s-9,4.9-12.3,8.5s-5.9,7.8-7.6,12.7c-1.8,4.9-2.6,10-2.6,15.5s0.9,10.7,2.6,15.6c1.7,4.8,4.3,9,7.6,12.6c3.3,3.6,7.4,6.4,12.3,8.5   s10.4,3.1,16.5,3.1s11.6-1,16.5-3.1s9-4.9,12.3-8.5s5.9-7.8,7.6-12.6c1.7-4.8,2.6-10,2.6-15.6C357.1,69.1,356.2,64,354.5,59.1z    M340.4,84.5c-0.9,3.1-2.3,5.8-4.2,8.1c-1.9,2.3-4.4,4.1-7.4,5.4s-6.6,2-10.8,2c-4.2,0-7.8-0.7-10.8-2s-5.4-3.1-7.4-5.4   c-1.9-2.3-3.3-5-4.2-8.1c-0.9-3.1-1.3-6.4-1.3-9.9s0.4-6.8,1.3-9.9c0.9-3.1,2.3-5.8,4.2-8.1c1.9-2.3,4.4-4.1,7.4-5.4   c3-1.3,6.6-2,10.8-2c4.2,0,7.8,0.7,10.8,2s5.4,3.1,7.4,5.4c1.9,2.3,3.3,5,4.2,8.1c0.9,3.1,1.3,6.4,1.3,9.9   C341.7,78.1,341.3,81.4,340.4,84.5z"/>
            <g>
                <path fill={logoColor} fillOpacity=".6"
                      d="M199.9,134.5c0.5-3.4,1.2-6.7,2.1-9.9c2.1-7.6,5.6-14.2,10.5-19.8c4.9-5.6,11.5-10.1,19.7-13.3    c8.2-3.2,18.4-4.8,30.4-4.8c5.5,0,11,0.2,16.5,0.7c-1.7-4.3-2.6-9-2.6-13.9c0-8.6,2.8-16.5,7.6-22.9c-7.7-0.7-15.5-1.1-23.4-1.1    c-16.2,0-30.6,2.6-43.3,7.8c-6.4,2.6-12.2,5.7-17.5,9.3L199.9,134.5L199.9,134.5z"/>
                <path fill={logoColor} fillOpacity=".6"
                      d="M293.3,209.7c-10.1,1.6-20.3,2.4-30.6,2.4c-12.1,0-22.2-1.6-30.4-4.8c-8.2-3.2-14.8-7.6-19.7-13.3    c-4.9-5.6-8.4-12.3-10.5-20c-0.9-3.2-1.6-6.6-2.1-10v5.4c0,11.3-2,21.9-5.9,31.8c-2.5,6.3-5.7,12.1-9.7,17.4    c0.5,0.5,0.9,1,1.4,1.5c8.6,9,19.1,16.1,31.7,21.3c12.6,5.2,27,7.8,43.3,7.8c10.3,0,20.4-0.6,30.3-1.8s18.9-2.9,27-5.3v-39.4    C311.6,205.8,303.3,208.1,293.3,209.7z"/>
            </g>
        </g>
    </svg>
}

const logoIcon = (classNames = "h-11 w-11", viewBox = "0 0 500 500") => {
    let logoColor = logoColors.ligth;
    return <svg xmlns="http://www.w3.org/2000/svg"
                className={classNames + " mx-auto"}
                fill="none"
                viewBox={viewBox}>
        <g>
            <path fill={logoColor}
                  d="M259.1,318.6c6.2-14.9,9.2-30.9,9.2-47.8v-7.7c-0.8-6.2-1.2-12.7-1.2-19.4c0-6.5,0.4-12.9,1.2-19V120.4   c-8,5.2-15.1,11.1-21.6,17.6c-13.2,13.4-23.4,29.3-30.3,47.6c-6.9,18.3-10.5,37.8-10.5,58.6s3.5,40.3,10.5,58.6   c6.3,16.4,15.1,30.9,26.5,43.4C249.4,338,254.9,328.7,259.1,318.6z"/>
            <path fill={logoColor}
                  d="M206.6,246.7V272c0,20.2-5.5,36.6-16.3,49.5c-10.8,12.8-27.7,19.2-50.7,19.2c-5,0-9.1-0.2-12.5-0.7   c-1.3-0.2-2.6-0.4-3.9-0.6c1.7,5.9,2.5,12.1,2.5,18.6c0,8.3-1.4,16.1-4,23.3c-1.3,3.5-2.8,6.7-4.6,9.8v0.9   c4.3,0.6,7.9,1.2,10.7,1.7c2.8,0.5,7.5,0.7,13.8,0.7c19.3,0,36.6-3.1,52-9.7c15.3-6.4,28.4-15.1,39.2-26.1c10.8-11,19.1-24,25-39   c5.8-14.9,8.7-30.9,8.7-47.8v-25.3h-59.9V246.7z"/>
            <g>
                <path fill={logoColor}
                      d="M4.6,357.7c0-8.2,1.4-16,4-23.2c2.6-7.4,6.4-13.6,11.4-19s11.1-9.7,18.5-12.8c7.4-3.1,15.5-4.7,24.8-4.7    s17.5,1.6,24.8,4.7c7.3,3.2,13.4,7.4,18.5,12.8c5,5.4,8.8,11.8,11.4,19c2.6,7.4,4,15,4,23.2c0,8.3-1.4,16.1-4,23.3    c-2.6,7.2-6.4,13.5-11.4,18.9c-5,5.4-11.1,9.7-18.5,12.8s-15.5,4.7-24.8,4.7s-17.5-1.6-24.8-4.7c-7.3-3.1-13.4-7.4-18.5-12.8    c-5-5.4-8.8-11.7-11.4-18.9C5.8,373.8,4.6,366,4.6,357.7z M27.6,357.7c0,5.2,0.6,10.2,2,14.9c1.4,4.7,3.5,8.7,6.3,12.2    c2.8,3.5,6.6,6.2,11,8.2c4.5,2,9.9,2.9,16.2,2.9s11.7-0.9,16.2-2.9c4.5-2,8.2-4.7,11-8.2c2.8-3.5,5-7.6,6.3-12.2    c1.4-4.7,2-9.7,2-14.9s-0.6-10.2-2-14.9c-1.4-4.7-3.5-8.7-6.3-12.2c-2.8-3.5-6.6-6.2-11-8.2c-4.5-2-9.9-2.9-16.2-2.9    s-11.7,0.9-16.2,2.9s-8.2,4.7-11,8.2c-2.8,3.5-5,7.6-6.3,12.2C28.3,347.4,27.6,352.5,27.6,357.7z"/>
            </g>
            <path fill={logoColor} fillOpacity=".5"
                  d="M503.4,107.4c-2.6-7.4-6.4-13.7-11.4-19c-5-5.4-11.1-9.7-18.5-12.8c-7.4-3.1-15.5-4.7-24.8-4.7   c-9.2,0-17.5,1.6-24.8,4.7c-7.4,3.2-13.4,7.4-18.5,12.8c-5,5.4-8.8,11.8-11.4,19c-2.6,7.3-4,15-4,23.2c0,8.3,1.4,16.1,4,23.3   c2.6,7.2,6.4,13.5,11.4,18.9s11.1,9.7,18.5,12.8c7.3,3.1,15.5,4.7,24.8,4.7c9.2,0,17.5-1.6,24.8-4.7c7.4-3.2,13.4-7.4,18.5-12.8   c5-5.4,8.8-11.7,11.4-18.9c2.6-7.2,4-15,4-23.3C507.3,122.4,506.1,114.6,503.4,107.4z M482.3,145.5c-1.4,4.7-3.5,8.7-6.3,12.2   c-2.8,3.5-6.6,6.2-11,8.2c-4.5,2-9.9,2.9-16.2,2.9c-6.3,0-11.7-0.9-16.2-2.9c-4.5-2-8.2-4.7-11-8.2c-2.8-3.5-5-7.6-6.3-12.2   c-1.4-4.7-2-9.7-2-14.9c0-5.3,0.6-10.2,2-14.9s3.5-8.7,6.3-12.2c2.8-3.5,6.6-6.2,11-8.2c4.5-2,9.9-2.9,16.2-2.9   c6.3,0,11.7,0.9,16.2,2.9s8.2,4.7,11,8.2c2.8,3.5,5,7.6,6.3,12.2c1.4,4.7,2,9.7,2,14.9C484.3,135.8,483.6,140.9,482.3,145.5z"/>
            <g>
                <path fill={logoColor} fillOpacity=".5"
                      d="M271.4,220.6c0.7-5.1,1.8-10.1,3.1-14.8c3.2-11.3,8.4-21.3,15.8-29.8c7.5-8.5,17.3-15.1,29.6-19.9    c12.4-4.8,27.6-7.2,45.7-7.2c8.3,0,16.5,0.3,24.7,1.1c-2.5-6.5-3.9-13.4-3.9-20.8c0-12.8,4.2-24.7,11.4-34.4    c-11.6-1.1-23.3-1.6-35.2-1.6c-24.4,0-46,3.9-65,11.8c-9.6,4-18.3,8.6-26.4,14v101.8H271.4z"/>
                <path fill={logoColor} fillOpacity=".5"
                      d="M411.6,333.6c-15.1,2.4-30.5,3.6-45.9,3.6c-18.1,0-33.3-2.4-45.7-7.2s-22.3-11.4-29.6-20    c-7.5-8.5-12.7-18.5-15.8-30c-1.4-4.8-2.3-9.9-3.1-15.1v8.1c0,17-2.9,32.9-8.9,47.8c-3.8,9.5-8.6,18.2-14.5,26.1    c0.6,0.7,1.4,1.5,2.1,2.2c12.8,13.4,28.8,24.1,47.7,31.9c18.9,7.9,40.5,11.8,65,11.8c15.4,0,30.7-0.8,45.5-2.6    c14.8-1.8,28.4-4.4,40.5-8V323C439,327.7,426.7,331.1,411.6,333.6z"/>
            </g>
        </g>
    </svg>
}

export {logotypeWithIcon, logoIcon, logotypeWithIconMedium}