import {createContext, useContext, useEffect, useMemo, useState} from "react";
import {useCookies} from "react-cookie";

import FetchClient from "./fetchClient";
import Application from "../app";

const AuthContext = createContext();

const AuthProvider = ({children}) => {
    const [cookies, setCookie, removeCookie] = useCookies();
    const [stores, setStores_] = useState(
        {
            token: (cookies ? cookies.token : ""),
            user: cookies ? (cookies.user) : null,
            course: cookies ? (cookies.course) : null
        });

    const setStore = (name, value) => {
        if (name === "token" && value === "logout") {
            stores["token"] = "";
            stores["course"] = null;
            stores["user"] = null;
            setStores_(stores);
            removeCookies();
        } else {
            stores[name] = value;
            setStores_(stores);
            updateCookies();
        }
    };

    const updateCookies = () => {
        if (stores["token"]) {
            setCookie('token', stores["token"], {path: '/'});
            FetchClient.defaults.headers.common["Authorization"] = "Bearer " + stores["token"];
        } else if (cookies.token) {
            // console.log(cookies.token);
            FetchClient.defaults.headers.common["Authorization"] = "Bearer " + cookies.token;
        } else {

        }

        if (stores["user"]) {
            setCookie('user', stores["user"], {path: '/'});
        }

        if (stores["course"]) {
            setCookie('course', stores["course"], {path: '/'});
        }
    };

    const removeCookies = () => {
        removeCookie('user', {path: '/'});
        removeCookie('token', {path: '/'});
        removeCookie('course', {path: '/'});
        FetchClient.defaults.headers.common["Authorization"] = "";
    }

    // Memoized value of the authentication context
    const contextValue = useMemo(
        () => ({
            stores,
            setStore,
            cookies,
            updateCookies
        }),
        [stores, cookies]
    );

    // Provide the authentication context to the children components
    return (
        <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
    );
};

export const useAuth = () => {
    return useContext(AuthContext);
};

export default AuthProvider;
