import axios from 'axios';

import {serverURL} from "./constants";
import Cookies from "js-cookie";

const FetchClient = () => {
    const defaultOptions = {
        baseURL: serverURL,
        method: 'get',
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }
    };
    let instance = axios.create(defaultOptions);

    instance.interceptors.request.use(function (config) {
        const token = Cookies.get('token');
        config.headers.Authorization = token ? `Bearer ${token}` : '';
        return config;
    });
    instance.interceptors.response.use(function (response) {
        return response;
    }, function (error) {
        if (error?.response?.status === 403) {
            document.cookie = 'token=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
            document.cookie = 'user=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
            document.cookie = 'course=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
            window.location.replace("/login");
        }
        return Promise.reject(error);
    });

    return instance;
};

export default FetchClient();