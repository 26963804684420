import React, {useState} from "react";
import Box from '@mui/material/Box';
import Popper from '@mui/material/Popper';
import Typography from '@mui/material/Typography';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';
const Badge = (props) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState("");
    const [popperContent, setPopperContent] = useState(-1);
  
    const handleClick = (popperContent) => (event) => {
      setAnchorEl(event.currentTarget);
      setOpen((prev) => !prev);
      setPopperContent(popperContent)
    };
    return(
        <>
        <div onMouseEnter={handleClick(0)} onMouseLeave={() => setOpen(false)} className="p-4 rounded-lg cursor-pointer">
                        <div className="flex flex-col items-center justify-between">
                            {props?.icon}
                        </div>
                        </div>
                        <Popper
                            sx={{ zIndex: 1200, width:"250px" }}
                            open={open}
                            anchorEl={anchorEl}
                            placement={"bottom"}
                            transition
                        >
                            {({ TransitionProps }) => (
                            <Fade {...TransitionProps} timeout={350}>
                                <Paper>
                                    <Box className={`h-15 ${props?.color} flex justify-center p-2`}>
                                        {props?.icon}

                                    </Box>
                                <Typography sx={{ p: 2 }}>{props?.content}</Typography>
                                </Paper>
                            </Fade>
                            )}
                        </Popper>
                </>
    )
}

export default Badge;