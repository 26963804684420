import {Link} from 'react-router-dom'
import {useLayoutContext} from "../layoutProvider";
import {sidebarMenuColors} from "../../utils/constants";

let currentColor = "";

const ChatWizardTypeItem = (props) => {
    const {sidebarSelectedMenuItem, setSidebarSelectedMenuItem} = useLayoutContext();
    if (sidebarSelectedMenuItem === 'chat_wizard_type') {
        currentColor = sidebarMenuColors.selected;
    } else {
        currentColor = sidebarMenuColors.unselected;
    }

    return (
        <div className="is-scrollbar-hidden course flex flex-col space-y-4 overflow-y-auto pt-6">
            <Link to="/chat/wizard/type" onClick={(event) => setSidebarSelectedMenuItem('chat_wizard_type')}
                  data-tooltip="Chat wizard"
                  data-placement="right"
                  className="tooltip-main-sidebar flex h-11 w-11 items-center justify-center rounded-lg outline-none transition-colors duration-200 hover:bg-primary/20 focus:bg-primary/20 active:bg-primary/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25">
              <svg fill={currentColor}   className="h-7 w-7" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <title>factory_line</title> <g id="a669c3f4-6dbf-4e74-bc75-7e15445e52bf" data-name="Layer 3"> <path d="M33.47,7.37a1,1,0,0,0-1,.06L23,13.77V8.26a1,1,0,0,0-1.64-.77L13.48,14H10V4.62a1,1,0,0,0-.78-1l-4-.9a1,1,0,0,0-.85.2A1,1,0,0,0,4,3.73V14H3a1,1,0,0,0-1,1V31a1,1,0,0,0,1,1H33a1,1,0,0,0,1-1V8.26A1,1,0,0,0,33.47,7.37ZM6,5l2,.44V14H6ZM32,30H4V16h9.83a1,1,0,0,0,.64-.23L21,10.37v5.28a1,1,0,0,0,1.56.83L32,10.14Z"></path> <rect x="6" y="17.99" width="8" height="2"></rect> <rect x="6" y="21.99" width="8" height="2"></rect> <rect x="6" y="25.99" width="8" height="2"></rect> <rect x="19" y="18.99" width="2" height="3"></rect> <rect x="19" y="24.99" width="2" height="3"></rect> <rect x="23" y="18.99" width="2" height="3"></rect> <rect x="23" y="24.99" width="2" height="3"></rect> <rect x="27" y="18.99" width="2" height="3"></rect> <rect x="27" y="24.99" width="2" height="3"></rect> </g> </g></svg>
            </Link>
        </div>);
};

export default ChatWizardTypeItem;