import PersonalizedLearningImage from "../../../../../images/personalized_learning.webp";
import CourseDesignImage from "../../../../../images/course_design.webp";
import AnalyticsImage from "../../../../../images/analytics.webp";
import ExerciseImage from "../../../../../images/exercise.webp";

export const helpList = [
    {
      id: 1,
      title: "Personalized Learning Feedback",
      image: PersonalizedLearningImage,
      content: `Teachers can review student chats and offer feedback to enhance understanding of generative AI and course concepts, while students can choose from various assistants with distinct personalities, creating a more engaging and personalized learning experience.`,
    },
    {
      id: 2,
      title: "Course Design and Delivery",
      image: CourseDesignImage,
      content: `JOURNiCRAFT allows teachers to utilize a customized generative AI model for course redesign, content structuring, and quiz creation, while facilitating direct communication between students and instructors, thereby fostering a collaborative and engaging learning environment.`,
    },
    {
      id: 3,
      title: "Insights and Analytics",
      image:AnalyticsImage,
      content: `JOURNiCRAFT's word-map feature provides teachers with insights from student chats by visualizing frequently used keywords, while students can earn badges and view their keyword maps, offering a visual representation of their progress and understanding of key concepts.`,
    },
    {
      id: 4,
      title: "Interactive Exercises",
      image:ExerciseImage,
      content: `JOURNiCRAFT enables teachers to create interactive exercises that require students to engage through chat and submit answers, fostering active learning and allowing for personalized, effective educational experiences while supporting student success.
`,
    }
  ];