import React, {useEffect, useState} from "react";
import { useLayoutContext } from "../../layout/layoutProvider";
import * as yup from 'yup';
import {useLocation, useOutletContext, useNavigate} from "react-router-dom";
import FetchClient from "../../utils/fetchClient";
import Dialog from '@mui/material/Dialog';

const AdminDashboard = () => {
    const location = useLocation();
    const {setLoader, setSidebarSelectedMenuItem} = useLayoutContext();
    const [assistantsList, setAssistantsList] = useState([]);
    const [createAssistantModal, setCreateAssistantModal] = useState(false);
    const [image, setImage] = useState(null); 
    const [preview, setPreview] = useState(null); 
  
    const handleImageChange = (event) => {
      const file = event.target.files[0];
      if (file) {
        setImage(file);
        const reader = new FileReader();
          reader.onloadend = () => {
          setPreview(reader.result);
        };
        reader.readAsDataURL(file);
      }
    };
  
    const handleRemoveImage = () => {
      setImage(null);
      setPreview(null);
    };
    const [assistant, setAssistant] = useState({
        id: -1, name: "", description: "",
        prompt: "", model: "", image: ""
    });
    const [openEditWizardTypeModal, setOpenEditWizardTypeModal] = useState(false);
    const [errorsList, setErrorsList] = useState([]);
    const user = useOutletContext().user;
    function updateMenu() {
        if (location.pathname.includes('admin_dashboard')) {
            setSidebarSelectedMenuItem('dashboard');
        }
    }
    useEffect(() => {
        updateMenu();
        setLoader(false);

    }, [location]);
        const handleCreateAssistant = () => {
            setCreateAssistantModal(true);
        }
        const handleSelectAssistant = (item) => {
            setOpenEditWizardTypeModal(true);
            setAssistant({
                name: item?.name, description: item?.description, prompt: item?.prompt,
                model: item?.model, id: item?.id, command: item?.command, image: item?.image_file_url
            });
        };
        const assistantsListTable = assistantsList?.length > 0 && assistantsList.map((assistant, index) => (
            <tr key={"question_" + assistant.id}
                className="border-y border-transparent cursor-pointer border-b-slate-200 dark:border-b-navy-500">
                <td className="whitespace-nowrap px-4 py-3 sm:px-5">{index + 1}</td>
                <td className="whitespace-nowrap px-4 py-3 sm:px-5 font-medium text-slate-700 dark:text-navy-100">{assistant.name}</td>
                <td className="whitespace-nowrap px-4 py-3 sm:px-5">{assistant.description}</td>
                <td className="whitespace-nowrap px-4 py-3 sm:px-5">
                    <img className="rounded-full" src={assistant.image_file_url} alt="assistant_img"/></td>
                <td className="whitespace-nowrap px-4 py-3 sm:px-5">{assistant.prompt}</td>
                <td className="whitespace-nowrap px-4 py-3 sm:px-5">{assistant.model}</td>
                <td className="whitespace-nowrap px-4 py-3 sm:px-5"> <svg className="ml-3" onClick={() => handleSelectAssistant(assistant)} width="22px" height="22px" viewBox="0 0 24 24"
                         fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <g id="SVGRepo_bgCarrier" stroke-width="0">
                        </g>
                        <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round">
                        </g>
                        <g id="SVGRepo_iconCarrier">
                            <path d="M13 21H21" stroke="#ffa408"
                                  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                            <path
                                d="M20.0651 7.39423L7.09967 20.4114C6.72438 20.7882 6.21446 21 5.68265 21H4.00383C3.44943 21 3 20.5466 3 19.9922V18.2987C3 17.7696 3.20962 17.2621 3.58297 16.8873L16.5517 3.86681C19.5632 1.34721 22.5747 4.87462 20.0651 7.39423Z"
                                stroke="#ffa408" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                            <path d="M15.3096 5.30981L18.7273 8.72755" stroke="#ffa408" stroke-width="2"
                                  stroke-linecap="round" stroke-linejoin="round"></path>
                            <path opacity="0.1"
                                  d="M18.556 8.90942L7.09967 20.4114C6.72438 20.7882 6.21446 21 5.68265 21H4.00383C3.44943 21 3 20.5466 3 19.9922V18.2987C3 17.7696 3.20962 17.2621 3.58297 16.8873L15.0647 5.35974C15.0742 5.4062 15.0969 5.45049 15.1329 5.48653L18.5506 8.90426C18.5524 8.90601 18.5542 8.90773 18.556 8.90942Z"
                                  fill="#ffa408"></path>
                        </g>
                    </svg></td>
            </tr>)
        );
        const keyupHandler = (event) => {
            if (event.key === 'Enter') {
                ceateAssistant();
            }
            return true;
        };
        const keyupEditHandler = (event) => {
            if (event.key === 'Enter') {
                editAssistant();
            }
            return true;
        };
    
        const ceateAssistant = async () => {
            const schema = yup.object().shape({
                assistant: yup.object().shape({
                    name: yup.string().required("Name of assistant is required !"),
                    description: yup.string().required("Description of assistant is required !"),
                    prompt: yup.string().required("Prompt is required !"),
                    model: yup.string().required("Model is required !"),
                })
            });
            try {
                await schema.validate({assistant}, {abortEarly: false});
                setLoader(true);
                const formData = new FormData();
                formData.append('name', assistant.name);
                formData.append('description', assistant.description);
                formData.append('prompt', assistant.prompt);
                formData.append('model', assistant.model);
                formData.append('image_file', image ? image : "");
                setLoader(false);
                setCreateAssistantModal(false);
                FetchClient({
                    method: 'post',
                    url: 'admin/assistants/create',
                    data: formData,
                    headers: {
                        'Content-Type': 'multipart/form-data'
                      },
                }).then(response => {
                    setAssistant({
                        id: -1, name: "", description: "",
                        prompt: "", model: "", image: ""
                    });
                    getAssistant();
                    setPreview(null);
                    setImage(null);
                    setErrorsList([]);
                    window.$notification({
                        text: 'Your wizard is created !',
                        variant: 'success',
                        position: 'center-top'
                    })
                }).catch((error) => {
                    window.$notification({
                        text: 'Oops! There was an error',
                        variant: 'error',
                        position: 'center-top'
                    });
                })
            } catch (err) {
                setErrorsList(err.inner);
            }
        };    
        const editAssistant = async () => {
            const schema = yup.object().shape({
                assistant: yup.object().shape({
                    name: yup.string().required("Name of wizard is required !"),
                    description: yup.string().required("Description of wizard is required !"),
                    prompt: yup.string().required("Prompt is required !"),
                    model:yup.string().required("Command is required !"),
                }),
            });
            try {
                await schema.validate({assistant}, {abortEarly: false});
                setLoader(true);
                const formData = new FormData();
                formData.append('name', assistant.name);
                formData.append('id', assistant.id);
                formData.append('description', assistant.description);
                formData.append('prompt', assistant.prompt);
                formData.append('is_deleted', false);
                formData.append('model', assistant.model);
                formData.append('image_file_url', image);
                setLoader(false);
                setOpenEditWizardTypeModal(false);
                FetchClient({
                    method: 'put',
                    url: 'admin/assistants/update',
                    data:formData,
                }).then(response => {
                    setAssistant({
                        name: "", description: "", model:"",
                        prompt: "", id: -1, command: "", image:""
                    });
                    getAssistant();
                    setErrorsList([]);
                    window.$notification({
                        text: 'Your wizard is updated !',
                        variant: 'success',
                        position: 'center-top'
                    })
                }).catch((error) => {
                    window.$notification({
                        text: 'Oops! There was an error',
                        variant: 'error',
                        position: 'center-top'
                    });
                })
            } catch (err) {
                setErrorsList(err.inner);
            }
        };
        const handleCloseCreateAssistantModal = () => {
            setCreateAssistantModal(false);
            setErrorsList([]);
            setAssistant({
                name: "", description: "", model:"",
                prompt: "", id: -1, command: "", image:""
            })
        }
        const handleCloseEditAssistant = () => {
            setOpenEditWizardTypeModal(false);
            setErrorsList([]);
            setAssistant({
                name: "", description: "", model:"",
                prompt: "", id: -1, command: "", image:""
            })
        }
        const getAssistant = () => (
            FetchClient({
                method: 'get',
                url: '/assistants'
            }).then(response => {
                setAssistantsList(response.data);
            })
        );
        useEffect(() => {
            updateMenu();
            getAssistant();
        }, [location]);
        return (
            <main className="main-content h-100vh mt-0">
                <div className="scrollbar-sm grow px-[calc(var(--margin-x)-.5rem)] mt-20 transition-all duration-[.25s]">
                    <div className={(user && user.user_role === 'admin' ? ' ' : ' hidden')}>
                        <button
                            class="btn ml-2 space-x-2 border font-medium text-white bg-primary/90 dark:text-white dark:bg-primary/50"
                            onClick={(event) => handleCreateAssistant(event)}>
                            <svg width="22px" height="22px" viewBox="0 0 24 24" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                                <g id="SVGRepo_iconCarrier">
                                    <circle cx="12" cy="12" r="10" stroke="#ffffff" stroke-width="1.5"></circle>
                                    <path d="M15 12L12 12M12 12L9 12M12 12L12 9M12 12L12 15" stroke="#ffffff"
                                          stroke-width="1.5" stroke-linecap="round"></path>
                                </g>
                            </svg>
                            <span>Add assistant</span>
                        </button>
                    </div>
                </div>
                <div
                    className="scrollbar-sm grow px-[calc(var(--margin-x)-.5rem)] py-2 mt-2 transition-all duration-[.25s]">
                        <div className="card mt-3">
                            <div className="overflow-x-auto">
                                <table className="is-hoverable w-full text-left">
                                    <thead>
                                    <tr>
                                        <th className="whitespace-nowrap rounded-tl-lg bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                            #
                                        </th>
                                        <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                            Name
                                        </th>
                                        <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                            Description
                                        </th>
                                        <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                            Image
                                        </th>
                                        <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                            Prompt
                                        </th>
                                        <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                            Model
                                        </th>
                                        <th className="whitespace-nowrap rounded-tr-lg bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                            Action
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {assistantsList.length > 0 && assistantsListTable}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                </div>
    
                <Dialog
                    open={createAssistantModal}
                    sx={{overflow: "visible", zIndex: 30}}
                    onClose={() => handleCloseCreateAssistantModal()}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <div className="flex justify-between bg-slate-200 px-4 py-3 dark:bg-navy-800 sm:px-5">
                        <h1 className="text-base font-medium text-slate-700 dark:text-navy-100">Create Assistant </h1>
                        <button
                            onClick={() => handleCloseCreateAssistantModal()}
                            className="btn -mr-1.5 h-7 w-7 rounded-full p-0 dark:text-white hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-4.5 w-4.5"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                strokeWidth="2"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M6 18L18 6M6 6l12 12"
                                />
                            </svg>
                        </button>
                    </div>
                    <div className="relative flex w-full max-w-lg origin-top flex-col overflow-hidden bg-white dark:bg-navy-700">
                        <form onKeyUp={(e) => keyupHandler(e)} className="scrollbar-sm text-navy-600 dark:text-navy-100 overflow-y-auto px-4 py-4 sm:px-5">
                            <div className="mt-4 space-y-4">
                                <label className="block">
                                    <span>Name:</span>
                                    <input
                                        className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                        name="name"
                                        value={assistant.name}
                                        onChange={(e) => setAssistant(assistant => ({
                                            ...assistant,
                                            name: e.target.value
                                        }))}
                                        type={"text"}
                                    />
                                    <span
                                        className="text-error-focus">{errorsList.filter((name) => name.path === "assistant.name")?.[0]?.errors?.[0]}</span>
    
                                </label>
                                <label className="block">
                                    <span>Description:</span>
                                    <input
                                        className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                        name="course_description"
                                        value={assistant.description}
                                        onChange={(e) => setAssistant(assistant => ({
                                            ...assistant,
                                            description: e.target.value
                                        }))}
                                        type={"text"}
                                    />
                                    <span
                                        className="text-error-focus">{errorsList.filter((name) => name.path === "assistant.description")?.[0]?.errors?.[0]}</span>
    
                                </label>
                                {assistant.is_wizard ?
                                    <label className="block">
                                        <span>Command:</span>
                                        <input
                                            className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                            name="course_description"
                                            value={assistant.command}
                                            onChange={(e) => setAssistant(assistant => ({
                                                ...assistant,
                                                command: e.target.value
                                            }))}
                                            type={"text"}
                                        />
                                        <span
                                            className="text-error-focus">{errorsList.filter((name) => name.path === "assistant.command")?.[0]?.errors?.[0]}</span>
    
                                    </label> : ""}
                                <label className="block">
                                    <span>Prompt:</span>
                                    <input
                                        className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                        name="teacher_description"
                                        value={assistant.prompt}
                                        onChange={(e) => setAssistant(assistant => ({
                                            ...assistant,
                                            prompt: e.target.value
                                        }))}
                                        type={"text"}
                                    />
                                    <span
                                        className="text-error-focus">{errorsList.filter((name) => name.path === "assistant.prompt")?.[0]?.errors?.[0]}</span>
                                </label>
                                <label className="block">
                                    <span>Model:</span>
                                    <input
                                        className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                        name="teacher_description"
                                        value={assistant.model}
                                        onChange={(e) => setAssistant(assistant => ({
                                            ...assistant,
                                            model: e.target.value
                                        }))}
                                        type={"text"}
                                    />
                                    <span
                                        className="text-error-focus">{errorsList.filter((name) => name.path === "assistant.model")?.[0]?.errors?.[0]}</span>
                                </label>
                                <label className="block">
                                        <span>Image:</span>
                                        <div className="max-w-sm mx-auto mt-1.5">
      <div className="flex flex-col w-full items-start justify-center">
        {preview ? (
          <div className="relative">
            <img
              src={preview}
              alt="Preview"
              className="w-full h-auto rounded-md border border-gray-300"
            />
            <button
              onClick={handleRemoveImage}
              className="absolute top-2 right-2 bg-red-500 text-white rounded-full px-2 py-1 text-xs"
            >
              Remove
            </button>
          </div>
        ) : (
          <div className="w-full h-48 flex items-center justify-center border-2 border-dashed border-gray-300 rounded-md">
            <span className="text-gray-500">No image selected</span>
          </div>
        )}
        <input
          type="file"
          accept="image/*"
          onChange={handleImageChange}
          className="mt-4"
        />
      </div>
    </div>
 
                                    </label> 
                            </div>
                        </form>
                    </div>
                    <div className="!text-right w-full px-4 py-4 bg-white dark:bg-navy-800">
                        <button
                            className="btn mx-4 min-w-[7rem] rounded-full border border-navy-300 font-medium text-slate-800 hover:bg-slate-150 focus:bg-slate-150 active:bg-slate-150/80 dark:border-navy-450 dark:text-navy-50 dark:hover:bg-navy-500 dark:focus:bg-navy-500 dark:active:bg-navy-500/90"
                            onClick={(event) => handleCloseCreateAssistantModal()}
                        >
                            Cancel
                        </button>
                        <button
                            onClick={ceateAssistant}
                            className="btn min-w-[7rem] rounded-full bg-primary font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90"
                        >
                            Add
                        </button>
                    </div>
                </Dialog>
                <Dialog
                    open={openEditWizardTypeModal}
                    onClose={() => handleCloseEditAssistant()}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <div className="flex justify-between bg-slate-200 px-4 py-3 dark:bg-navy-800 sm:px-5">
                        <h1 className="text-base font-medium text-slate-700 dark:text-navy-100">Edit Assistant </h1>
                        <button
                            onClick={() => handleCloseEditAssistant()}
                            className="btn -mr-1.5 h-7 w-7 rounded-full p-0 hover:bg-slate-300/20 dark:text-white focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-4.5 w-4.5"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                strokeWidth="2"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M6 18L18 6M6 6l12 12"
                                />
                            </svg>
                        </button>
                    </div>
                    <div
                        className="relative flex w-full max-w-lg origin-top flex-col overflow-hidden bg-white dark:bg-navy-700">
                        <form onKeyUp={(e) => keyupEditHandler(e)}
                              className="scrollbar-sm dark:text-white overflow-y-auto px-4 py-4 sm:px-5">
                            <div className="mt-4 space-y-4">
                                <label className="block">
                                    <span>Name:</span>
                                    <input
                                        className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                        name="name"
                                        value={assistant.name}
                                        onChange={(e) => setAssistant(assistant => ({
                                            ...assistant,
                                            name: e.target.value
                                        }))}
                                        type={"text"}
                                    />
                                    <span
                                        className="text-error-focus">{errorsList.filter((name) => name.path === "assistant.name")?.[0]?.errors?.[0]}</span>
    
                                </label>
                                <label className="block">
                                    <span>Description:</span>
                                    <input
                                        className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                        name="course_description"
                                        value={assistant.description}
                                        onChange={(e) => setAssistant(assistant => ({
                                            ...assistant,
                                            description: e.target.value
                                        }))}
                                        type={"text"}
                                    />
                                    <span
                                        className="text-error-focus">{errorsList.filter((name) => name.path === "assistant.description")?.[0]?.errors?.[0]}</span>
    
                                </label>
                                <label className="block">
                                    <span>Prompt:</span>
                                    <input
                                        className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                        name="teacher_description"
                                        value={assistant.prompt}
                                        onChange={(e) => setAssistant(assistant => ({
                                            ...assistant,
                                            prompt: e.target.value
                                        }))}
                                        type={"text"}
                                    />
                                    <span
                                        className="text-error-focus">{errorsList.filter((name) => name.path === "assistant.prompt")?.[0]?.errors?.[0]}</span>
                                </label>
                                    <label className="block">
                                        <span>Model:</span>
                                        <input
                                            className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                            name="teacher_description"
                                            value={assistant.model}
                                            onChange={(e) => setAssistant(assistant => ({
                                                ...assistant,
                                                model: e.target.value
                                            }))}
                                            type={"text"}
                                        />
                                        <span
                                            className="text-error-focus">{errorsList.filter((name) => name.path === "assistant.model")?.[0]?.errors?.[0]}</span>
                                    </label>
                                    <label className="block">
                                        <span>Image:</span>
                                        <div className="max-w-sm mx-auto mt-1.5">
      <div className="flex flex-col w-full items-start justify-center">
        {assistant?.image && preview === null ?
        <img
        src={assistant?.image}
        alt="Preview"
        className="w-full h-auto rounded-md border border-gray-300"
      />:
        preview ? (
          <div className="relative">
            <img
              src={preview}
              alt="Preview"
              className="w-full h-auto rounded-md border border-gray-300"
            />
            <button
              onClick={handleRemoveImage}
              className="absolute top-2 right-2 bg-red-500 text-white rounded-full px-2 py-1 text-xs"
            >
              Remove
            </button>
          </div>
        ) : (
          <div className="w-full h-48 flex items-center justify-center border-2 border-dashed border-gray-300 rounded-md">
            <span className="text-gray-500">No image selected</span>
          </div>
        )}
        <input
          type="file"
          accept="image/*"
          onChange={handleImageChange}
          className="mt-4"
        /> 
      </div>
    </div>
 
                                    </label> 
    
                            </div>
                        </form>
                    </div>
                    <div className="!text-right w-full px-4 py-4 bg-white dark:bg-navy-800">
                        <button
                            className="btn mx-4 min-w-[7rem] rounded-full border border-navy-300 font-medium text-slate-800 hover:bg-slate-150 focus:bg-slate-150 active:bg-slate-150/80 dark:border-navy-450 dark:text-navy-50 dark:hover:bg-navy-500 dark:focus:bg-navy-500 dark:active:bg-navy-500/90"
                            onClick={(event) => handleCloseEditAssistant()}
                        >
                            Cancel
                        </button>
                        <button
                            onClick={editAssistant}
                            className="btn min-w-[7rem] rounded-full bg-primary font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90"
                        >
                            Edit
                        </button>
                    </div>
                </Dialog>
            </main>
        );
    }
export default AdminDashboard;