import {CardContent, CardMedia, Grid, Paper, Typography} from '@mui/material';
import {styled} from '@mui/material/styles';
import PricingCard from './pricingComponentCard';

const PricingContainer = () => {

    const freePlanFeatures = [
        '1 course',
        '3 Assistants',
        'Unlimited Features'
    ];
    const teacherPlanFeatures = [
        "Unlimited courses",
        "to 100 Students",
        "10 Assistants",
        "Unlimited Features",
    ];
    const programPlan = [
        "Unlimited courses",
        "Unlimited Students",
        "Unlimited Assistants",
        "Unlimited Features"
    ];
    return (
        <Grid container spacing={1} id="pricing" className='max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto'>
            <h2 className="text-2xl font-bold md:text-4xl md:leading-tight dark:text-white text-gray-800 mb-8">
                Pricing
            </h2>
            <Grid container spacing={2}>
                <Grid item className='!mt-2' xs={12} sm={6} md={6} lg={4}>
                    <PricingCard
                        title="Free Plan"
                        price="Free"
                        description="Start Free for One Course"
                        features={freePlanFeatures}
                        linkText="Sign up"
                    />
                </Grid>
                <Grid className=' !mt-2' item xs={12} sm={6} md={6} lg={4}>
                    <PricingCard
                        title="Teacher Plan"
                        price="$ 2"
                        description="Per students"
                        features={teacherPlanFeatures}
                        linkText="Sign up"
                    />
                </Grid>
                <Grid className=' !mt-2' item xs={12} sm={6} md={6} lg={4}>
                    <PricingCard
                        title="Program/Institute Plan"
                        price="Contact Us"
                        description="Customized"
                        features={programPlan}
                        linkText="Contact us"
                        openModal = {true}
                    />
                </Grid>
            </Grid>
        </Grid>
    )
}
export default PricingContainer;