import { Link } from "react-router-dom";
import { useLayoutContext } from "../layoutProvider";
import { sidebarMenuColors } from "../../utils/constants";

let currentColor = "";

const UserListItem = (props) => {
  const { sidebarSelectedMenuItem, setSidebarSelectedMenuItem, setLoader } =
    useLayoutContext();
  if (sidebarSelectedMenuItem === "exercise_list") {
    currentColor = sidebarMenuColors.selected;
  } else {
    currentColor = sidebarMenuColors.unselected;
  }

  return (
    <div className="is-scrollbar-hidden exercise flex flex-col space-y-4 overflow-y-auto pt-6">
      <Link
        to="/exercise_list"
        onClick={(event) => setSidebarSelectedMenuItem("exercise_list")}
        data-tooltip="Exercise List"
        data-placement="right"
        className="tooltip-main-sidebar flex h-11 w-11 items-center justify-center rounded-lg outline-none transition-colors duration-200 hover:bg-primary/20 focus:bg-primary/20 active:bg-primary/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25"
      >
        <svg
          className="ml-2 mt-1 h-7 w-7"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 42 42"
        >
          <g
            stroke={currentColor}
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g id="Vivid-Icons" transform="translate(-750.000000, -644.000000)">
              <g id="Icons" transform="translate(37.000000, 169.000000)">
                <g id="doc" transform="translate(702.000000, 468.000000)">
                  <g transform="translate(11.000000, 7.000000)">
                    <rect
                      id="Rectangle-path"
                      fill={currentColor}
                      fillOpacity="0.4"
                      fill-rule="nonzero"
                      x="0"
                      y="0"
                      width="26"
                      height="34"
                    ></rect>
                    <path
                      d="M5,0 L5,11 L8,8 L11,11 L11,0 L5,0 Z M17,12 L26,12 L26,14 L17,14 L17,12 Z M17,18 L26,18 L26,20 L17,20 L17,18 Z M17,23 L26,23 L26,25 L17,25 L17,23 Z"
                      id="Shape"
                      fill={currentColor}
                      fillOpacity="1"
                    ></path>
                    <path
                      d="M13.995,12.005 L13.995,13.987 L17.005,13.987 L17.005,12.005 L13.995,12.005 Z M13.995,18.015 L13.995,20 L17.005,20 L17.005,18.016 L13.995,18.016 L13.995,18.015 Z M13.995,22.993 L13.995,25.007 L17.005,25.007 L17.005,22.993 L13.995,22.993 Z"
                      id="Shape"
                      fill={currentColor}
                      fillOpacity="0"
                    ></path>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </Link>
    </div>
  );
};

export default UserListItem;
