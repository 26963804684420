import React, { useState, useEffect } from 'react';
import ReactHTMLParser, { domToReact } from 'html-react-parser';
const Typewriter = ({ text, delay }) => {
  const [currentText, setCurrentText] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isBlinking, setIsBlinking] = useState(true);
  const bold = (text) => {
    var bold = /\*\*(.*?)\*\*/gm;
    var html = text.replace(bold, '<strong>$1</strong>');
    return html;
  };
  const parsedContent = ReactHTMLParser(bold(currentText), {
    replace: ({ tagName, children }) => {
      if (tagName === 'html') {
        return domToReact(children);
      }
    }
  });

  useEffect(() => {
    const blinkingInterval = setInterval(() => {
      setIsBlinking((prevState) => !prevState);
    }, 500);

    return () => {
      clearInterval(blinkingInterval);
    };
  }, []);

  useEffect(() => {
    const words = text.split(' ');
    if (currentIndex < words.length) {
      const timeout = setTimeout(() => {
        setCurrentText((prevText) => (prevText ? prevText + ' ' : '') + words[currentIndex]);
        setCurrentIndex((prevIndex) => prevIndex + 1);
      }, delay);

      return () => clearTimeout(timeout);
    }
  }, [currentIndex, delay, text]);

  return (
    <span>
      {parsedContent}
      {currentIndex < text.split(' ').length && <span className="animate-blink">|</span>}
    </span>
  );
};

export default Typewriter;
