import { colors } from "@mui/material";
import { components } from "react-select";

export const Option = (props) => {
    const handleClick = (event) => {
        event.stopPropagation(); 
        props.selectOption(props.data); 
    };
    const isSelected = props.isSelected; 
    return (
        <div 
            onClick={handleClick} 
            style={{
                padding: "8px",
                display: "flex",
                alignItems: "center",
                // justifyContent: "space-between",
                fontSize: "14px",
                cursor: "pointer",
                // transition: "background-color 0.3s",
                backgroundColor: isSelected ? "bg-slate-500" : "inherit", // Remove background if selected
            }} 
            className={`  dark:hover:bg-navy-600 dark:focus:bg-navy-600 ${isSelected ? 'bg-transparent' : ''}`} // Ensure no background is applied if selected
        >
            {props?.data?.image_file_url && (
                <div className="avatar h-10 w-10">
                    <img 
                        className="rounded-full" 
                        width={50} 
                        height={50} 
                        alt="" 
                        src={props?.data?.image_file_url} 
                    />
                </div>
            )}
            <div className="flex flex-col pl-3">
                {/* <components.Option {...props} /> */}
                <span className={` text-slate-700 line-clamp-1 dark:text-navy-100`}>
                    {props?.data?.name}
                </span>
                <span className="text-xs text-slate-500 dark:text-navy-300">
                    {props?.data?.description}
                </span>
            </div>
        </div>
    );
};
export const CustomStyles = {
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    menu: (base) => ({
      ...base,
      color:localStorage.getItem("dark-mode") === "dark" ? '#ffffff' : "#000000",

      background: localStorage.getItem("dark-mode") === "dark" ? "#26334D" : "#ffffff",
    }),
    control: (base) => ({
      ...base,
      color:localStorage.getItem("dark-mode") === "dark" ? '#ffffff' : "#000000",
      background:  localStorage.getItem("dark-mode") === "dark" ? "#26334D" : "#ffffff",
      border: localStorage.getItem("dark-mode") === "dark" ? '1px solid #ffffff' :
      '1px solid #cecece', 
      boxShadow: 'none',
      '&:hover': {
        border: localStorage.getItem("dark-mode") === "dark" ? '1px solid #cecece ' :
        '1px solid #ffffff',
      },
    }),
    option: (base, { isFocused, isSelected }) => ({
      ...base,
      background: isFocused
        ?  localStorage.getItem("dark-mode") === "dark" ? ' #082f66 ' :
        '#cecece'
        :  isSelected
        ?  localStorage.getItem("dark-mode") === "dark" ? '#19283d' : "#cecece"
        : localStorage.getItem("dark-mode") === "dark" ? '#19283d ' : "#ffffff", 
      cursor: 'pointer',
      color:localStorage.getItem("dark-mode") === "dark" ? '#ffffff' : "#19283d "
    }),
    // multiValue: (base) => ({
    //   ...base,
    //   background: localStorage.getItem("dark-mode") === "dark" ? '#64748b' : '#cbd5e1', 
    //   color:"#ffffff"
    // }),
    // MultiValueGeneric:(base)=>({
    //     ...base,
    //     color:"#ffffff"
    // })
  };