import FetchClient from "../../utils/fetchClient";
import React, {useEffect, useState} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {useParams} from "react-router";
import { useAuth } from "../../utils/authProvider";
import { useLayoutContext } from "../../layout/layoutProvider";
import ReactQuill from "react-quill";
import { Dialog, Tab, Tabs } from "@mui/material";
import * as yup from 'yup';
import moment from "moment";
import Select from 'react-select';
import TabPanel from "../../components/tab_pannel";
import { CustomStyles } from "../../components/selectOptions";

const TeacherMessage = () => {
    const location = useLocation();
    const store = useAuth();
    const params = useParams();
    const courseId = store?.stores?.course?.id;
    const user = store?.stores?.user;
    const [users, setUsers] = useState([]);
    const [sentMessages, setSentMessages] = useState([]);
    const [selectedUserList, setSelectedUserList] = useState([]);
    const [messageItem, setMessageItem] = useState({subject:"", body:"", reply_status:false, receivers_info:[]})
    const [sendModal, setSendModal] = useState(false);
    const [errorsList, setErrorsList] = useState([]);
    const {setLoader, setSidebarSelectedMenuItem} = useLayoutContext();
    const [receivedMessages, setReceivedMessages] = useState([]);
    const navigate = useNavigate();
    const [tab, setTab] = useState(0);

    const handleChange = (event, newValue) => {
      setTab(newValue);
    };
    const getSentMessages = () =>{
      FetchClient({
        method: 'get',
        url: 'course/teacher/course_emails/sent',
        params: {
            course_id: courseId,
        }
    }).then(response => {
        setSentMessages(response?.data?.items);
    })

    }
    const getReceivedMessages = () =>{
      FetchClient({
        method: 'get',
        url: 'course/teacher/course_emails',
        params: {
            course_id: courseId,
        }
    }).then(response => {
      setReceivedMessages(response?.data?.items);
    })

    }
    useEffect(() => {
        getUsers();
        updateMenu();
        getSentMessages();
        getReceivedMessages();
    }, [location]);
    function updateMenu() {
        if (location.pathname.includes('messages')) {
            setSidebarSelectedMenuItem('messages');
        }
    }

      const getUsers = () => {
        FetchClient({
            method: 'get',
            url: 'course/' + courseId + '/users',
            params: {
                course_id: courseId,
            }
        }).then(response => {
            setUsers(response?.data?.items);
        })
    }
    const keyUpHandler = (event) => {
        if (event.key === 'Enter') {
            postMessage();
        }
        return true;
    };
    const postMessage = async() =>{
      const schema = yup.object().shape({
        messageItem: yup.object().shape({
          body: yup.string().required("Body of message is required !"),
          subject: yup.string().required("Subject of message is required !"),
        }),
        selectedUserList: yup.array().min(1, "Selection of receivers is required !").required("")
      
    });
    try {
        await schema.validate( {messageItem, selectedUserList} , { abortEarly: false });  
        
        FetchClient({
          method: 'post',
          url: 'course/course_email/create',
          data: {
              course_id: courseId,
              sender_id: user.id,
              sender_name:user.name,
              subject: messageItem.subject,
              body: messageItem.body,
              receivers_info: selectedUserList.map(item => { 
                return { receiver_id: item.id, receiver_name: item.name }; })
          }
      }).then(response => {
        setMessageItem({subject:"", body:"", reply_status:false, receivers_info:[]});
        setSendModal(false);
        getSentMessages();
        getReceivedMessages();
        setSelectedUserList([]);
        window.$notification({
          text: 'Your message sent successfully !',
          variant: 'success',
          position: 'center-top'
      })
      }).catch((error)=>{
          window.$notification({
          text: 'Oops! There was an error',
          variant: 'error',
          position: 'center-top'
      });
      })
    } catch (err) {
        setErrorsList(err.inner);
    }
    }
    const handleSelectMessage = (msg) =>{
        navigate(`/messages/${msg?.id}`,  {replace: true});   
      }
    const handleSelectUsers = (selected, index) =>{
            if (index?.option?.id === -1) {
                setSelectedUserList(users);
            } else
              setSelectedUserList(selected);
    }
    return (
        <div className="messege-container main-content mt-24 p-4">
            <div className="flex flex-col">
            <button 
              onClick={()=> setSendModal(true)}
              className={"btn flex justify-between h-4 w-36 p-5 whitespace-nowrap bg-primary font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90 "}>
                <svg className="h-5 w-5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M8 11H8.01M12 11H12.01M16 11H16.01M21 20L17.6757 18.3378C17.4237 18.2118 17.2977 18.1488 17.1656 18.1044C17.0484 18.065 16.9277 18.0365 16.8052 18.0193C16.6672 18 16.5263 18 16.2446 18H6.2C5.07989 18 4.51984 18 4.09202 17.782C3.71569 17.5903 3.40973 17.2843 3.21799 16.908C3 16.4802 3 15.9201 3 14.8V7.2C3 6.07989 3 5.51984 3.21799 5.09202C3.40973 4.71569 3.71569 4.40973 4.09202 4.21799C4.51984 4 5.0799 4 6.2 4H17.8C18.9201 4 19.4802 4 19.908 4.21799C20.2843 4.40973 20.5903 4.71569 20.782 5.09202C21 5.51984 21 6.0799 21 7.2V20Z" stroke="#ffffff" 
                stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                <span>
                     Compose
                </span>
            </button>
            <main class="w-full  py-6">
      <Tabs
        className="!w-full "
          value={tab}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons={false}
          aria-label="scrollable prevent tabs example"
        >
          <Tab label="Sent" />
          <Tab label="Received" />
        </Tabs>
        <TabPanel value={tab} index={0}>
              <div class="card">
                {sentMessages.length > 0 ? sentMessages.map((msg, index)=>(
                <div key={index}  onClick={()=>handleSelectMessage(msg)} className="flex cursor-pointer flex-col rounded-t-lg border-b p-2.5 font-semibold hover:bg-slate-100 dark:border-navy-500 dark:text-navy-100 dark:hover:bg-navy-600 sm:flex-row sm:items-center">
                <div className={`flex items-center justify-between text-slate-400`}>
                  <div className="flex space-x-2 sm:w-72">
                    <div className="flex items-center space-x-2">
                      <div className="avatar h-6 w-6">
                        {index + 1}
                      </div>
                      <h3 className="line-clamp-1">{msg?.sender_id === user?.id ? "You" : msg?.sender_name}</h3>
                    </div>
                  </div>
                  <div className="shrink-0 px-1 text-xs sm:hidden">{moment(msg?.creation_date).format("MMMM DD YYYY")}</div>
                </div>
                <div className="flex flex-1 items-center justify-between space-x-2">
                  <div className="flex items-center space-x-2 px-2">
                    <span className={`line-clamp-1 text-slate-400 `} >{msg?.subject ? msg?.subject : "No subject"} -</span>
                    <div className="line-clamp-1 text-slate-400" dangerouslySetInnerHTML={{__html: msg?.body}}></div>
                  </div>
                </div>
                <div className={`hidden px-2 text-xs+ sm:flex  text-slate-400"`}>{moment(msg?.creation_date).format("MMMM DD YYYY")}</div>
              </div> 
                )): <div class="w-fit m-12 alert flex justify-center rounded-full bg-warning/10 py-4 px-4 text-warning dark:bg-warning/15 sm:px-5">
                    There is no message!
                </div>}
              </div>
        </TabPanel>
        <TabPanel value={tab} index={1}>
              <div class="card">
                {receivedMessages.length > 0 ? receivedMessages.map((msg, index)=>(
                <div key={index}  onClick={()=>handleSelectMessage(msg)} class="flex cursor-pointer flex-col rounded-t-lg border-b p-2.5 font-semibold hover:bg-slate-100 dark:border-navy-500 dark:text-navy-100 dark:hover:bg-navy-600 sm:flex-row sm:items-center">
                <div className={`flex items-center justify-between ${msg?.is_read ? "text-slate-400" : "text-slate-600"}`}>
                  <div className="flex space-x-2 sm:w-72">
                    <div className="flex items-center space-x-2">
                      <div className="avatar h-6 w-6">
                        {index + 1}
                      </div>
                      <h3 className="line-clamp-1">{msg?.sender_name}</h3>
                    </div>
                  </div>
                  <div className="shrink-0 px-1 text-xs sm:hidden">{moment(msg?.creation_date).format("MMMM DD YYYY")}</div>
                </div>
                <div className="flex flex-1 items-center justify-between space-x-2">
                  <div className="flex items-center space-x-2 px-2">
                    <span className={`${msg?.is_read ? "text-slate-400" : "text-slate-600"} line-clamp-1`} >{msg?.subject ? msg?.subject : "No subject"} -</span>
                    <div className="line-clamp-1 text-slate-400" dangerouslySetInnerHTML={{__html: msg?.body}}></div>
                  </div>
                </div>
                <div className={`hidden px-2 text-xs+ sm:flex ${msg?.is_read ? "text-slate-400" : "text-slate-600"}`}>{moment(msg?.creation_date).format("MMMM DD YYYY")}</div>
              </div> 
                )): <span className="p-2 flex justify-center text-error-focus font-bold">No data to be shown !</span>}
              </div>
        </TabPanel>
            </main>
            </div>
            <Dialog
                open={sendModal}
                onClose={()=>setSendModal(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <div
                    className="modal-content relative flex w-full max-w-lg origin-top flex-col overflow-hidden bg-white dark:bg-navy-700">
                    <div className="flex justify-between rounded-t-lg bg-slate-200 px-4 py-3 dark:bg-navy-800 sm:px-5">
                        <h3 className="text-base font-medium text-slate-700 dark:text-navy-100">
                            Compose your message
                        </h3>
                        <button
                            onClick={() => setSendModal(false)}
                            className="btn -mr-1.5 h-7 w-7 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-4.5 w-4.5"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                strokeWidth="2"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M6 18L18 6M6 6l12 12"
                                />
                            </svg>
                        </button>
                    </div>
                    <form className="scrollbar-sm dark:text-navy-100 overflow-y-auto px-4 py-4 sm:px-5"
                        onKeyUp={(event) => keyUpHandler(event)}>
                        <div class="h-fulloverflow-clip rounded-xl flex flex-col">
                            <span className="text-error-focus">{errorsList.filter((name) => name.path === "selectedUserList")?.[0]?.errors?.[0]}</span>
                            <Select
                                styles={CustomStyles}
                                placeholder="Select Students"
                                noOptionsMessage={() => 'Student dose not exist !'}
                                isClearable
                                isSearchable
                                loadingMessage={() => 'Loading ...'}
                                  closeMenuOnSelect={true}
                                  value={selectedUserList}
                                  isMulti={true}
                                  getOptionLabel={(option) => option?.name}
                                  getOptionValue={(option) => option?.id}
                                  options={[  {
                                    id: -1, name: "All"
                                  }, ...users]}
                                  onChange={handleSelectUsers}
                                />  
                        </div>
                      
                        <div className="mt-4 space-y-4">
                            <label className="block">
                                <span>Subject:</span>
                                <input
                                    className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                    placeholder="Your Message Title"
                                    name="title"
                                    value={messageItem.subject}
                                    onChange={(e) => setMessageItem(messageItem=> ({
                                      ...messageItem,
                                      subject:e.target.value
                                    }))}
                                    type={"text"}
                                />
                                <span className="text-error-focus">{errorsList.filter((name) => name.path === "messageItem.subject")?.[0]?.errors?.[0]}</span>
                            </label>
                            <label className="block">
                                <span>body:</span>
                                <ReactQuill theme="snow" className="h-40 w-full inline-block"
                                  value={messageItem.body}
                                  onChange={(data)=> setMessageItem(messageItem=> ({
                                    ...messageItem,
                                    body:data.replace(/<(.|\n)*?>/g, '').trim().length === 0 && !data.includes("<img") ? "" : data
                                  }))}
                                />
                                <span className="text-error-focus">{errorsList.filter((name) => name.path === "messageItem.body")?.[0]?.errors?.[0]}</span>
                            </label>

                        </div>
                        {/* <label className="flex p-4">
                            <span>Can students reply?</span>
                            <input className="form-checkboxis-outline ml-2.5 h-5 w-5 border-slate-400/70 bg-slate-100 before:bg-slate-500 checked:border-slate-500 hover:border-slate-500 focus:border-slate-500 dark:border-navy-500 dark:bg-navy-900 dark:before:bg-navy-200 dark:checked:border-navy-200 dark:hover:border-navy-200 dark:focus:border-navy-200"
                                type="checkbox"
                                onChange={(e) => setMessageItem(messageItem=> ({
                                  ...messageItem,
                                  reply_status:e.target.checked}))}
                            />
                                                
                        </label> */}
                    </form>
                    <div className="space-x-2 p-3 text-right">
                        <button
                            data-close-modal
                            className="btn min-w-[7rem] rounded-full border border-slate-300 font-medium text-slate-800 hover:bg-slate-150 focus:bg-slate-150 active:bg-slate-150/80 dark:border-navy-450 dark:text-navy-50 dark:hover:bg-navy-500 dark:focus:bg-navy-500 dark:active:bg-navy-500/90"
                            onClick={(event) => setSendModal(false)}
                        >
                            Cancel
                        </button>
                        <button
                            data-close-modal
                            onClick={()=>postMessage()}
                            className="btn min-w-[7rem] rounded-full bg-primary font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90"
                        >
                            Send
                        </button>
                    </div>
                </div>                  
            </Dialog>
           
        </div>
    )
}

export default TeacherMessage;